export const collectRolesFromKonvaObj = ({ pages, selectedPages, shapes }) => {
  let roles = [];

  if (pages && selectedPages) {
    // for manuscrpts/general-form
    const index = selectedPages[0] - 1;
    if (pages && pages[index] && pages[index].stage_json) {
      roles = pages[index].stage_json.children[0].children
        .filter(child => child.attrs && (child.attrs.link || child.attrs.staticLink))
        .map(
          child =>
            child.attrs &&
            ((child.attrs.link && child.attrs.link.role) ||
              (child.attrs.staticLink && child.attrs.staticLink.role))
        );
    }
  } else if (shapes) {
    // for format
    roles = shapes
      .filter(shape => shape.link)
      .map(shape => {
        if (shape.staticLink && shape.staticLink.role) {
          return shape.staticLink.role;
        } else if (shape.link.type == 'Puzzle') return shape.link.role + ' - ' + shape.link.format;
        else return shape.link.role;
      });
  }

  return roles;
};

// this to be used in the future instead of the above function + to be used now for other purposes
export const collectLinksFromKonvaObj = ({ shapes }) => {
  // if any attr added like blockHeight you should check minLinksArr function
  let links = [];

  if (shapes) {
    links = shapes
      .filter(shape => shape.link)
      .map(shape => {
        if (shape.staticLink) {
          // for corss words hints blocks collect block height and inject it in the links array
          if (shape.staticLink.role === 'word-list-block-text')
            shape.staticLink.blockHeight = shape.height;
          return shape.staticLink;
        } else {
          return shape.link;
        }
      });
  }

  return links;
};

const minLinksArr = links_arr => {
  return links_arr.map(link => {
    let { role, cols, rows, puzzleShape, blockHeight } = link;
    if (link.type == 'Puzzle') role = link.role + ' - ' + link.format;
    return { role, cols, rows, puzzleShape, blockHeight };
  });
};

export const collectLinksMapFromFormats = ({ pages }) => {
  let map = [];
  pages.forEach((page, index) => {
    if (page && page.links_arr) {
      // if format_id exists in the map, set links_arr to the existing index
      const existingIndex = map.findIndex(item => item.format_id === page.id);
      if (existingIndex !== -1) map[existingIndex].indecies.push(index);
      else
        map.push({ indecies: [index], format_id: page.id, links_arr: minLinksArr(page.links_arr) });
    }
  });
  return map;
};

// links map will look like this
// {
// 	"links_map": [
// 		{
// 			"indecies": [
// 				0
// 			],
// 			"format_id": 165,
// 			"links_arr": [
// 				{
// 					"role": "classic-table-of-contents-text"
// 				},
// 				{}
// 			]
// 		},
// 		{
// 			"indecies": [
// 				1,
// 				2,
// 				3,
// 				4,
// 				5,
// 				6,
// 				7,
// 				8,
// 				9,
// 				10,
// 				11,
// 				12,
// 				13,
// 				14,
// 				15,
// 				16,
// 				17,
// 				18,
// 				19,
// 				20,
// 				21,
// 				22,
// 				23,
// 				24,
// 				25,
// 				26,
// 				27,
// 				28,
// 				29,
// 				30,
// 				31,
// 				32,
// 				33,
// 				34,
// 				35,
// 				36,
// 				37,
// 				38,
// 				39,
// 				40,
// 				41,
// 				42,
// 				43,
// 				44,
// 				45,
// 				46,
// 				47,
// 				48,
// 				49
// 			],
// 			"format_id": 123,
// 			"links_arr": [
// 				{
// 					"role": "word-search-grid - puzzle",
// 					"cols": 11,
// 					"rows": 11,
// 					"puzzleShape": "normal"
// 				},
// 				{
// 					"role": "classic-word-list-text",
// 					"cols": 3,
// 					"rows": 4
// 				},
// 				{
// 					"role": "Page Number"
// 				},
// 				{
// 					"role": "Problem Number"
// 				},
// 				{},
// 				{
// 					"role": "Word List Theme"
// 				}
// 			]
// 		},
// 		{
// 			"indecies": [
// 				50,
// 				51,
// 				52,
// 				53,
// 				54,
// 				55,
// 				56,
// 				57,
// 				58,
// 				59,
// 				60,
// 				61,
// 				62,
// 				63,
// 				64,
// 				65,
// 				66,
// 				67,
// 				68,
// 				69,
// 				70,
// 				71,
// 				72,
// 				73,
// 				74,
// 				75,
// 				76,
// 				77,
// 				78,
// 				79,
// 				80,
// 				81,
// 				82,
// 				83,
// 				84,
// 				85,
// 				86,
// 				87,
// 				88,
// 				89,
// 				90,
// 				91,
// 				92,
// 				93,
// 				94,
// 				95,
// 				96,
// 				97,
// 				98,
// 				99,
// 				100
// 			],
// 			"format_id": 124,
// 			"links_arr": [
// 				{
// 					"role": "Page Number"
// 				},
// 				{
// 					"role": "Solution Number"
// 				},
// 				{},
// 				{
// 					"role": "word-search-grid - solution",
// 					"cols": 11,
// 					"rows": 11,
// 					"puzzleShape": "normal"
// 				},
// 				{
// 					"role": "Word List Theme"
// 				}
// 			]
// 		}
// 	]
// }

// segments should look like this for the above links_map

// [
//   {
//     "to": 50, // the last page has role // has role "word-search-grid - puzzle"
//     "to1": 101, // the last page has role // has role "word-search-grid - solution"
//     "from": 1, // the first page has role "word-search-grid - puzzle"
//     "name": "Word Search",
//     "from1": 51, // the first page has role "word-search-grid - solution"
//     "pageSelectorMode": "2-ranges",
//     "pageSelectorHandSide": 0
//     "rows": 11,
//     "cols": 11,
//     "nWords": 9, // (rows * cols) of object with role classic-word-list-text if exists
//     "puzzleShape": "normal",
//     }

// ]

// iterate over links_map and return first index of indecies array within the object that has the role "Page Number" in links_arr
export const findFirstPageIndex = links_map => {
  for (const slice_map of links_map) {
    const index = slice_map.links_arr.findIndex(link => link.role === 'Page Number');
    if (index !== -1) {
      return slice_map.indecies[0];
    }
  }
  return 0;
};

export const createSegmentsFromLinksMap = links_map => {
  const findName = role => {
    const names = {
      'word-search': 'Word Search',
      'cross-words': 'Cross Words',
      sudoku: 'Sudoku',
      trivia: 'Trivia',
      'number-search': 'Number Search',
    };

    for (const key in names) {
      if (role && role.includes(key)) {
        return names[key];
      }
    }
    return '';
  };

  const divided_segments = links_map.map(slice_map => {
    let from;
    let to;
    let from1;
    let to1;
    let rows;
    let cols;
    let nWords;
    let puzzleShape;
    let name;
    let npuzzles;

    // get the number of puzzles per page
    let puzzlesPerPage = slice_map.links_arr.filter(
      link => link.role && link.role.includes('puzzle')
    ).length;

    // get values from the links_arr
    for (const link of slice_map.links_arr) {
      // (from, to) will be the first and last `slice_map.indecies` that has the puzzle grid role
      // (from1, to1) will be the first and last `slice_map.indecies` that has the solution grid role
      // puzzleShape will be the puzzleShape of the puzzle grid
      // rows will be rows of the puzzle grid
      // cols will be cols of the puzzle grid
      // nWords will be the number of words of the classic-word-list-text (cols * rows)
      // name will be the puzzle name
      if (link.role && link.role.includes('puzzle')) {
        // if we have more than one puzzle grid in the same page find their count from the links array

        name = findName(link.role);
        from = slice_map.indecies[0] + 1;
        to = slice_map.indecies[slice_map.indecies.length - 1] + 1;
        rows = link.rows;
        cols = link.cols;
        puzzleShape = link.puzzleShape;
        npuzzles = slice_map.indecies.length * puzzlesPerPage;
      }

      if (link.role && link.role.includes('classic-word-list')) {
        nWords = link.rows * link.cols;
      }

      if (link.role && link.role.includes('solution')) {
        name = findName(link.role);
        from1 = slice_map.indecies[0] + 1;
        to1 = slice_map.indecies[slice_map.indecies.length - 1] + 1;
        rows = link.rows;
        cols = link.cols;
        puzzleShape = link.puzzleShape;
      }
    }

    return {
      name,
      from,
      to,
      from1: from1 || to,
      to1: to1 || to,
      rows,
      cols,
      nWords,
      puzzleShape,
      npuzzles,
      pageSelectorHandSide: 0,
      pageSelectorMode: '2-ranges',
    };
  });

  // filter divided_segments from objects with no name the reduce them to segments by combining objects with the same name, rows, cols, puzzleShape
  return divided_segments
    .filter(segment => segment.name)
    .reduce((acc, curr) => {
      const { name, rows, cols, puzzleShape } = curr;
      const index = acc.findIndex(
        obj =>
          obj.name === name &&
          obj.rows === rows &&
          obj.cols === cols &&
          obj.puzzleShape === puzzleShape
      );

      if (index !== -1) {
        acc[index].from = acc[index].from || curr.from;
        acc[index].to = acc[index].to || curr.to;
        acc[index].from1 = acc[index].from1 || curr.from1;
        acc[index].to1 = acc[index].to1 || curr.to1;
        acc[index].npuzzles = acc[index].npuzzles || curr.npuzzles;
        acc[index].pageSelectorHandSide = 0;
        acc[index].pageSelectorMode = '2-ranges';
      } else {
        acc.push(curr);
      }

      return acc;
    }, []);
};

function sortFunc(sortingArr, a, b) {
  return sortingArr.indexOf(a.attrs.id) - sortingArr.indexOf(b.attrs.id);
}

export const getShapesNodes = (stageRef, idsArr) => {
  let list = [];
  if (stageRef.current && stageRef.current.children[0]) {
    list = stageRef.current.children[0].children.filter(shape => idsArr.includes(shape.attrs.id));
  }
  return list;
};

export const calculateTextWidth = ({
  text,
  fontSize,
  fontFamily,
  fontStyle,
  minWidth,
  canvasWidth,
  addedSpace = 5,
}) => {
  // Create a temporary canvas element
  let canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // Set the font for the context
  context.font = fontSize + 'px ' + fontFamily;

  // fontStyle
  if (fontStyle) {
    context.font = fontStyle + ' ' + context.font;
  }

  // Measure the width of the text
  let width = context.measureText(text).width + addedSpace;

  // Clean up the temporary canvas
  canvas = null;

  if (minWidth > width) {
    return minWidth;
  }

  if (width > canvasWidth) {
    return canvasWidth;
  }

  return width;
};
