import axios from 'axios';
import urls from 'settings/urls.js';

// Manuscripts
export async function getManuscripts(category_id, sort, tags, offset) {
  const requestOptions = {
    method: 'GET',
    url: urls.manuscripts(category_id, sort, tags, offset),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function getOneManuscript(id) {
  const requestOptions = {
    method: 'GET',
    url: urls.oneManuscript(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function addManuscript(data) {
  const requestOptions = {
    method: 'POST',
    url: urls.newManuscript,
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function editManuscript(data, id) {
  const requestOptions = {
    method: 'PATCH',
    url: urls.editManuscript(id),
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function deleteManuscript(id) {
  const requestOptions = {
    method: 'DELETE',
    url: urls.deleteManuscript(id),
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function updateManuscriptVariables(data, id) {
  const requestOptions = {
    method: 'POST',
    url: urls.updateManuscriptVariables(id),
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function startTriviaListEngine(params, difficulty) {
  let extraParmas = '';
  difficulty.forEach(
    lvl => (extraParmas += lvl ? (extraParmas.length > 1 ? '&' : '?') + 'difficulty=' + lvl : '')
  );
  const requestOptions = {
    method: 'GET',
    url: urls.triviaListEngine + extraParmas,
    params,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function startWordSearchEngine(data) {
  const requestOptions = {
    method: 'POST',
    url: urls.wordSearchEngine,
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function startArticleWordSearchEngine(data) {
  const requestOptions = {
    method: 'POST',
    url: urls.articleWordSearchEngine,
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function startNumberSearchEngine(data) {
  const requestOptions = {
    method: 'POST',
    url: urls.numberSearchEngine,
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function startSudokuEngine(data) {
  const requestOptions = {
    method: 'POST',
    url: urls.sudokuEngine,
    data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function startCrossWordsEngine(data) {
  const requestOptions = {
    method: 'POST',
    url: urls.crossWordsEngine,
    data: {
      ...data,
      fast_mode: true,
    },
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function startCodeWordEngine(data) {
  const requestOptions = {
    method: 'POST',
    url: urls.codeWordEngine,
    data: {
      ...data,
      fast_mode: true,
    },
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}

export async function startStoriesEngine(data) {
  const requestOptions = {
    method: 'GET',
    url: urls.storyEngine,
    params: data,
  };

  const res = await axios(requestOptions);
  return res && res.data ? res.data : {};
}
