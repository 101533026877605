import React, { useEffect, useState } from 'react';
import { getBoundries, getMirrorX, getMirrorY } from 'helpers/shapes';

import CheckboxLock from 'components/forms/elements/CheckboxLock';
import IncludeSvg from 'components/common/IncludeSvg';
import InputNumber from 'components/forms/elements/InputNumber';
import InputRange from 'components/forms/elements/InputRange';
import {
  SUDOKU_GRIDS,
  WORDSEARCH_DESIGN,
  CROSSWORDS_DESIGN,
  CODEWORD_DESIGN,
} from 'settings/puzzels';
import SelectWrapper from 'components/forms/elements/SelectWrapper';
import fontFamilies from 'settings/fonts';
import { getUniqueId } from 'helpers/generators';
import { useStudioContext } from 'hooks/studio';
import { calculateTextWidth } from 'helpers/studio';
import { newEl } from 'helpers/shapes';
import { DIFFICULTY_OPTS, PUZZLE_SHAPE_OPTS } from 'settings/puzzels';

import './FormatStudioFormText.scss';
import { LetterA } from 'assets/icons/LetterA';
import { LetterQ } from 'assets/icons/LetterQ';
import { LetterC } from 'assets/icons/LetterC';
import { LetterS } from 'assets/icons/LetterS';

const FONT_STYLES_OPTS = [
  { label: 'Normal', value: 'normal' },
  { label: 'Medium', value: '500' },
  { label: 'Bold', value: 'bold' },
  { label: 'Extra-Bold', value: '800' },
  { label: 'Italic', value: 'italic' },
  { label: 'Italic bold', value: 'italic bold' },
];

function FormatStudioFormText({
  className,
  selectedShapeId,
  secondarySelectedIds,
  setSelectedShapeId,
  shapes,
  setShapes,
  updateHistory,
  onChange,
  setNoOtherInput,
  format,
  isPuzzleTab = false,
}) {
  const { dragEl, dragImgSrc, dragImgID, dragImgWidth, dragImgHeight } = useStudioContext();

  // Size & Position Section
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [keepRatio, setKeepRatio] = useState(true);
  const [isKeepRationDisabled, setKeepRatioDisabled] = useState(true);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);

  // text section
  const [fontFamily, setFontFamily] = useState('Arial');
  const [fontSize, setFontSize] = useState(12);
  const [fontSizeInputChanging, setFontSizeInputChanging] = useState(false);
  const [fontStyle, setFontStyle] = useState('Normal');
  const [textAlign, setTextAlign] = useState('left');
  const [letterSpacing, setLetterSpacing] = useState(0);
  const [lineHeight, setLineHeight] = useState(1);

  // Appearance section
  const [opacity, setOpacity] = useState(100);
  const [opacityChecked, setOpacityChecked] = useState(true);
  const [fillColorChecked, setFillColorChecked] = useState(true);
  const [fillColor, setFillColor] = useState('#000000');
  const [strokeChecked, setStrokeChecked] = useState(true);

  // marked words
  const [markedWordsFillColor, setMarkedWordsFillColor] = useState('#ff0000');
  const [markedWordsUnderlineChecked, setMarkedWordsUnderlineChecked] = useState(true);
  const [markedWordsFontStyle, setMarkedWordsFontStyle] = useState('Bold');

  // Rings
  const [ringsFillColorChecked, setRingsFillColorChecked] = useState(true);
  const [ringsFillColor, setRingsFillColor] = useState('transparent');

  const [strokeColor, setStrokeColor] = useState('#000000');
  const [strokeWidth, setStrokeWidth] = useState(0);

  // rect corners
  const [cornerRadiusEq, setCornerRadiusEq] = useState(true);
  const [cornerTL, setCornerTL] = useState(0);
  const [cornerTR, setCornerTR] = useState(0);
  const [cornerBR, setCornerBR] = useState(0);
  const [cornerBL, setCornerBL] = useState(0);
  // classic word list
  const [cols, setCols] = useState(3);
  const [rows, setRows] = useState(3);

  // Grid
  const [gridText, setGridText] = useState('');
  const [gridCols, setGridCols] = useState(9);
  const [gridRows, setGridRows] = useState(9);
  const [smallNumFontSize, setSmallNumFontSize] = useState(10);

  // Word search
  const [commaSeparatedWords, setCommaSeparatedWords] = useState(
    'COOL, PUZZLES, WORDSEARCH, KIDS, ADULTS'
  );

  const [difficulty, setDifficulty] = useState('easy');
  const [puzzleShape, setPuzzleShape] = useState('Normal');
  const [nWords, setNWords] = useState(5);

  const [gridStroke1Checked, setGridStroke1Checked] = useState(true);
  const [gridStroke1Color, setGridStroke1Color] = useState('#000000');
  const [gridStroke1Width, setGridStroke1Width] = useState(1);
  const [gridStroke2Checked, setGridStroke2Checked] = useState(true);
  const [gridStroke2Color, setGridStroke2Color] = useState('#000000');
  const [gridStroke2Width, setGridStroke2Width] = useState(0.1);
  const [gridStroke3Checked, setGridStroke3Checked] = useState(true);
  const [gridStroke3Color, setGridStroke3Color] = useState('#000000');
  const [gridStroke3Width, setGridStroke3Width] = useState(0.1);

  const [gridStroke4Checked, setGridStroke4Checked] = useState(true);
  const [gridStroke4Color, setGridStroke4Color] = useState('#ffffff');
  const [gridStroke4Width, setGridStroke4Width] = useState(12);
  const [gridStroke5Checked, setGridStroke5Checked] = useState(true);
  const [gridStroke5Color, setGridStroke5Color] = useState('#ffffff');

  const [sudokuGrid, setSudokuGrid] = useState({ item: SUDOKU_GRIDS[3], label: '3 X 3' });

  const { setDragEl } = useStudioContext();

  const [contentType, setContentType] = useState('word_search');

  const content_types_options = [
    { value: 'word_search', text: 'Word Search' },
    { value: 'number_search', text: 'Number Search' },
    { value: 'trivia', text: 'Trivia' },
    { value: 'sudoku', text: 'Sudoku' },
    { value: 'cross_words', text: 'Cross Words' },
    { value: 'code_word', text: 'Code Word' },
    { value: 'story', text: 'Story' },
  ];

  const handleQuickInsertion = e => {
    const type = e.target.dataset.type;
    const id = getUniqueId(type);
    let [shape] = newEl(type, 0, 0, {
      dragEl,
      dragImgSrc,
      dragImgID,
      dragImgWidth,
      dragImgHeight,
    });
    setSelectedShapeId(null);
    setShapes(shapes.concat(shape));
    setSelectedShapeId(id);
    updateHistory();
  };

  const handleAlignment = type => {
    // result -> row, col
    // ref -> start, center, end, space-between
    // type -> result + ref

    const axe = type.includes('row') ? 'x' : 'y';
    const ref = type.includes('start') ? '1' : type.includes('end') ? '2' : '';

    const shapesList = [...shapes];
    let parentId = selectedShapeId;
    let childrenIds = [...secondarySelectedIds];

    if (!type.includes('space') && (!parentId || !childrenIds.length)) return;
    if (type.includes('space') && (!parentId || !childrenIds.length > 1)) return;

    // adjustment only for space between to ignore order

    if (type.includes('space')) {
      const ids = [parentId, ...childrenIds];
      const selectedShapes = ids.map(id => {
        const index = shapes.findIndex(shape => shape.id === id);
        return shapes[index];
      });
      const selectedShapesSortedByAxe = selectedShapes.sort((a, b) => {
        return a[axe] - b[axe];
      });
      childrenIds = selectedShapesSortedByAxe.map(shape => shape.id);
      parentId = childrenIds.shift();
      childrenIds.unshift(childrenIds.pop()); // get ref child at the beginning of the arr
    }
    // adjustment only for space between to ignore order

    const parentIndex = shapesList.findIndex(node => node.id === parentId);
    const parentBoundries = getBoundries(shapesList[parentIndex]);
    const childrenBoundries = childrenIds.map(childId => {
      const childIndex = shapesList.findIndex(node => node.id === childId);
      return getBoundries(shapesList[childIndex]);
    });

    if (!type.includes('space')) {
      childrenBoundries.forEach(childBoundries => {
        const delta = parentBoundries[axe + ref] - childBoundries[axe + ref];
        const childIndex = shapesList.findIndex(shape => shape.id === childBoundries.id);
        const child = { ...shapesList[childIndex] };
        child[axe] = child[axe] + delta;
        shapesList[childIndex] = child;
      });
    } else {
      const parentAxeDim = parentBoundries[axe + '2'] - parentBoundries[axe + '1'];
      const firstChildDim = childrenBoundries[0][axe + '2'] - childrenBoundries[0][axe + '1'];
      const totalClear =
        childrenBoundries[0][axe] - parentBoundries[axe] - parentAxeDim / 2 - firstChildDim / 2;
      let totalGap = totalClear;
      childrenBoundries.forEach((childBoundries, index) => {
        if (index > 0) totalGap -= childBoundries[axe + '2'] - childBoundries[axe + '1'];
      });
      const gap = totalGap / childrenBoundries.length;
      let lastChildAxe = parentBoundries[axe];
      let lastChildAxeDim = parentAxeDim;
      childrenBoundries.forEach((childBoundries, index) => {
        if (index >= 1) {
          const childAxeDim = childBoundries[axe + '2'] - childBoundries[axe + '1'];
          const childIndex = shapesList.findIndex(shape => shape.id === childBoundries.id);
          const child = { ...shapesList[childIndex] };
          child[axe] = lastChildAxe + lastChildAxeDim / 2 + gap + childAxeDim / 2;
          shapesList[childIndex] = child;
          lastChildAxe = child[axe];
          lastChildAxeDim = childBoundries[axe + '2'] - childBoundries[axe + '1'];
        }
      });
    }

    setShapes(shapesList);
  };

  // link checkboxes to inputs
  useEffect(() => {
    if (!opacityChecked) {
      setOpacity(0);
    } else setOpacity(100);
  }, [opacityChecked]);

  useEffect(() => {
    if (!fillColorChecked) {
      setFillColor('rgba(0,0,0,0)');
    } else {
      if (selectedShapeId) {
        if (selectedShapeId.includes('text')) setFillColor('#000000');
        else if (!fillColor || fillColor === 'rgba(0,0,0,0)') setFillColor('#02A3FF');
        else if (fillColor && fillColor !== 'rgba(0,0,0,0)') setFillColor(fillColor);
      }
    }
  }, [fillColorChecked]);

  useEffect(() => {
    if (!ringsFillColorChecked) {
      setRingsFillColor('rgba(0,0,0,0)');
    } else if (!ringsFillColor || fillColor === 'rgba(0,0,0,0)') setRingsFillColor('#444444');
  }, [ringsFillColorChecked]);

  useEffect(() => {
    if (!strokeChecked) {
      setStrokeWidth(0);
    } else if (!strokeWidth) {
      setStrokeWidth(1);
    }
  }, [strokeChecked]);

  // links form to shape
  useEffect(() => {
    if (selectedShapeId && !selectedShapeId.includes('text') && !selectedShapeId.includes('grid')) {
      setKeepRatio(true);
    } else {
      setKeepRatio(false);
    }
  }, [selectedShapeId]);

  useEffect(() => {
    if (selectedShapeId) {
      const index = shapes.findIndex(item => item.id === selectedShapeId);
      if (index === -1) return;

      if (shapes[index].id.includes('text')) {
        setWidth(Math.round((shapes[index].width * 100) / 96) / 100);
        if (shapes[index].id.includes('story'))
          setHeight(Math.round((shapes[index].height * 100) / 96) / 100);
      } else if (shapes[index].id.includes('triangle')) {
        setHeight(Math.round((shapes[index].radius * 1.5 * 100) / 96) / 100);
        setWidth(Math.round((((shapes[index].radius * 3) / Math.sqrt(3)) * 100) / 96) / 100);
      } else if (shapes[index].id.includes('circle')) {
        setWidth(Math.round((shapes[index].radiusX * 2 * 100) / 96) / 100);
        setHeight(Math.round((shapes[index].radiusY * 2 * 100) / 96) / 100);
      } else if (shapes[index].id.includes('star')) {
        setWidth(Math.round((shapes[index].outerRadius * 2 * 100) / 96) / 100);
        setHeight(Math.round((shapes[index].outerRadius * 2 * 100) / 96) / 100);
      }

      // else if (shapes[index].id.includes('line')) {
      //   setHeight(0);
      //   setWidth(
      //     Math.round(
      //       ((shapes[index].points[2] - shapes[index].points[0]) * 100) / 96
      //     ) / 100
      //   );
      // }
      else {
        setWidth(Math.round((shapes[index].width * 100) / 96) / 100);
        setHeight(Math.round((shapes[index].height * 100) / 96) / 100);
      }

      if (shapes[index].id.includes('rect') || shapes[index].id.includes('image')) {
        const corners = shapes[index].cornerRadius;
        if (corners) {
          setCornerTL(corners[0]);
          setCornerTR(corners[1]);
          setCornerBR(corners[2]);
          setCornerBL(corners[3]);
        } else {
          setCornerTL(0);
          setCornerTR(0);
          setCornerBR(0);
          setCornerBL(0);
        }
      }

      if (
        selectedShapeId.includes('image') ||
        selectedShapeId.includes('rect') ||
        selectedShapeId.includes('circle') ||
        selectedShapeId.includes('word-search') ||
        selectedShapeId.includes('number-search') ||
        selectedShapeId.includes('grid')
      )
        setKeepRatioDisabled(false);
      else setKeepRatioDisabled(true);

      setX(shapes[index].x);
      setY(-shapes[index].y);

      if (!shapes[index].id.includes('image') && shapes[index].fill)
        setFillColor(shapes[index].fill);

      setStrokeColor(shapes[index].stroke || '#000000');
      setStrokeWidth(shapes[index].strokeWidth || 0);

      // checkboxes
      if (shapes[index].strokeWidth) setStrokeChecked(true);
      else setStrokeChecked(false);

      if (shapes[index].fill && shapes[index].fill !== 'rgba(0,0,0,0)') setFillColorChecked(true);
      else setFillColorChecked(false);

      if (shapes[index].opacity || shapes[index].opacity === undefined) setOpacityChecked(true);
      else setOpacityChecked(false);

      setRotation(shapes[index].rotation || 0);

      setScaleX(shapes[index]?.scaleX ?? 1);
      setScaleY(shapes[index]?.scaleY ?? 1);

      // text (only)
      if (selectedShapeId.includes('text') || selectedShapeId.includes('grid')) {
        setFontFamily(shapes[index].fontFamily || 'Arial');
        setFontSize(shapes[index].fontSize || '12');
        setFontStyle(shapes[index].fontStyle || 'Normal');
        setTextAlign(shapes[index].align || 'left');
        setLetterSpacing(shapes[index].letterSpacing || 0);
        setLineHeight(shapes[index].lineHeight || 1);
      }
      if (
        selectedShapeId.includes('classic-word-list') ||
        selectedShapeId.includes('classic-number-list')
      ) {
        setCols(shapes[index].staticLink.cols || 3);
        setRows(shapes[index].staticLink.rows || 2);
      }

      if (
        selectedShapeId.includes('article-word-list') ||
        selectedShapeId.includes('word-list-block')
      ) {
        setMarkedWordsFillColor(shapes[index].staticLink.markedWordsStyles.fill || '#ff0000');
        setMarkedWordsUnderlineChecked(
          shapes[index].staticLink.markedWordsStyles.textDecoration === 'underline'
        );
        setMarkedWordsFontStyle(shapes[index].staticLink.markedWordsStyles.fontStyle || 'bold');
      }

      if (selectedShapeId.includes('grid')) {
        setGridCols(shapes[index].staticLink.cols);
        setGridRows(shapes[index].staticLink.rows);
        if (shapes[index].staticLink.commaSeparatedWords)
          setCommaSeparatedWords(shapes[index].staticLink.commaSeparatedWords);
        if (shapes[index].staticLink.difficulty) setDifficulty(shapes[index].staticLink.difficulty);
        if (shapes[index].staticLink.puzzleShape)
          setPuzzleShape(shapes[index].staticLink.puzzleShape);
        if (shapes[index].staticLink.nWords) setNWords(shapes[index].staticLink.nWords);

        setGridText(shapes[index].text);

        setGridStroke1Checked(shapes[index].staticLink.borders.checked1 || false);
        setGridStroke1Color(shapes[index].staticLink.borders.color1 || '#000000');
        setRingsFillColor(shapes[index].staticLink.ringsFillColor || 'transparent');
        setGridStroke1Width(shapes[index].staticLink.borders.width1 || 1);
        setGridStroke2Checked(shapes[index].staticLink.borders.checked2 || false);
        setGridStroke2Color(shapes[index].staticLink.borders.color2 || '#000000');
        setGridStroke2Width(shapes[index].staticLink.borders.width2 || 0.1);
        setGridStroke3Checked(shapes[index].staticLink.borders.checked3 || false);
        setGridStroke3Color(shapes[index].staticLink.borders.color3 || '#000000');
        setGridStroke3Width(shapes[index].staticLink.borders.width3 || 0.1);
        setGridStroke4Checked(shapes[index].staticLink.borders.checked4 || false);
        setGridStroke4Color(shapes[index].staticLink.borders.color4 || '#ffffff');
        setGridStroke4Width(shapes[index].staticLink.borders.width4 || 12);
        setGridStroke5Checked(shapes[index].staticLink.borders.checked5 || false);
        setGridStroke5Color(shapes[index].staticLink.borders.color5 || '#ffffff');
        setSmallNumFontSize(shapes[index].staticLink.smallNumFontSize || 10);
        // or for backward compitability some old shapes havn't this property
      }

      if (selectedShapeId.includes('sudoku-grid')) {
        setSudokuGrid(shapes[index].staticLink.grid);
      }
    } else {
      // set back everything to defaults if no element selected
      setWidth(0);
      setHeight(0);
      setX(0);
      setY(0);
      setRotation(0);
      setOpacity(100);
      setFillColor('#000000');
      setStrokeColor('#000000');
      setStrokeWidth(1);
      setRingsFillColor('transparent');
      setScaleY(1);
      setScaleX(1);
      setFontFamily('Arial');
      setFontSize('12');
      setFontStyle('Normal');
      setTextAlign('left');
      setLetterSpacing(0);
      setLineHeight(1);
      setCornerTL(0);
      setCornerTR(0);
      setCornerBR(0);
      setCornerBL(0);
      setCols(3);
      setRows(2);
      setCommaSeparatedWords('COOL, PUZZLES, WORDSEARCH, KIDS, ADULTS');
      setDifficulty('easy');
      setPuzzleShape('normal');
      setNWords(5);
      setGridText('');
      setGridCols(9);
      setGridRows(9);
      setMarkedWordsFillColor('#ff0000');
      setMarkedWordsUnderlineChecked(true);
      setMarkedWordsFontStyle('Bold');
      setGridStroke1Checked(true);
      setGridStroke1Color('#000000');
      setGridStroke1Width(2.5);
      setGridStroke2Checked(true);
      setGridStroke2Color('#000000');
      setGridStroke2Width(0.5);
      setGridStroke3Checked(true);
      setGridStroke3Color('#000000');
      setGridStroke3Width(0.5);
      setGridStroke4Checked(true);
      setGridStroke4Color('#ffffff');
      setGridStroke4Width(12);
      setGridStroke5Checked(true);
      setGridStroke5Color('#ffffff');
      setSudokuGrid(SUDOKU_GRIDS[3]);
      setSmallNumFontSize(6);
    }
  }, [selectedShapeId, shapes]);

  useEffect(() => {
    if (selectedShapeId) {
      const index = shapes.findIndex(item => item.id === selectedShapeId);
      if (index === -1) return;
      if (shapes[index].opacity) setOpacity(shapes[index].opacity * 100);
      else setOpacity(100);
    }
  }, [selectedShapeId]);

  // links stage to form
  useEffect(() => {
    if (selectedShapeId && width) {
      const list = [...shapes];
      const index = shapes.findIndex(item => item.id === selectedShapeId);
      const node = list[index];

      // Change the width
      if (node.id.includes('triangle')) {
        node.radius = (width * Math.sqrt(3) * 96) / 3;
      } else if (node.id.includes('circle')) {
        if (keepRatio) node.radiusY = (node.radiusY * width * 96) / 2 / node.radiusX;
        node.radiusX = (width * 96) / 2;
      } else if (node.id.includes('star')) {
        node.outerRadius = (width * 96) / 2;
      }
      // else if (node.id.includes('line')) {
      // const oldWidth = node.points[2] - node.points[0];
      // const dx = width * 96 - oldWidth;
      // node.points[2] += dx;
      // // node.points[0] -= dx / 2;
      // node.width = width;

      // }
      else {
        if (keepRatio)
          // Change height too
          node.height = (node.height * width * 96) / node.width;
        node.width = width * 96;
      }
      onChange(node, true);
    }
  }, [width]);

  useEffect(() => {
    if (selectedShapeId && height) {
      const list = [...shapes];
      const index = shapes.findIndex(item => item.id === selectedShapeId);
      const node = list[index];
      if (node.id.includes('triangle')) {
        node.radius = (height * 96 * 2) / 3;
      } else if (node.id.includes('circle')) {
        if (keepRatio) node.radiusX = (node.radiusX * height * 96) / 2 / node.radiusY;
        node.radiusY = (height * 96) / 2;
      } else if (node.id.includes('star')) {
        node.outerRadius = (height * 96) / 2;
      } else if (node.id.includes('story')) {
        node.height = height * 96;
      } else {
        if (!node.id.includes('text')) {
          if (keepRatio) node.width = (node.width * height * 96) / node.height;
          node.height = height * 96;
        }
      }
      onChange(node, true);
    }
  }, [height]);

  useEffect(() => {
    if (selectedShapeId) {
      const list = [...shapes];
      const index = shapes.findIndex(item => item.id === selectedShapeId);
      const node = list[index];
      if (!node) return;

      // shapes
      node.opacity = opacity / 100;

      if (node.fill) node.fill = fillColor;
      node.stroke = strokeColor;
      node.strokeWidth = strokeWidth;

      if (selectedShapeId.includes('star') && (height || width)) {
        node.innerRadius = 0.5 * Math.max(width * 96, height * 96);
      }

      node.x = x ? x : 0;
      node.y = y ? y * -1 : 0;
      node.rotation = Math.round(rotation);

      // if item not already has scaleY, scaleX, rotation
      if (!node.scaleX) node.scaleX = 1;
      if (!node.scaleY) node.scaleY = 1;
      if (!node.rotation) node.rotation = 0;

      // vertical mirror
      if (node.scaleX !== scaleX) {
        node.x = node.x - getMirrorX(node) * scaleX;
        node.rotation = 360 - node.rotation;
      }

      node.scaleX = scaleX;

      // Horizontal Mirror
      if (node.scaleY !== scaleY) {
        node.y = node.y - getMirrorY(node) * scaleY;
        node.rotation = 360 - node.rotation;
      }

      node.scaleY = scaleY;

      // Text and wordlists
      if (selectedShapeId.includes('text') || selectedShapeId.includes('grid')) {
        node.fontFamily = fontFamily;
        node.fontSize = fontSize;
        node.fontStyle = fontStyle;
        node.align = textAlign;
        node.letterSpacing = letterSpacing;
        node.lineHeight = lineHeight;
        if (
          fontSizeInputChanging &&
          !selectedShapeId.includes('word-list') &&
          !selectedShapeId.includes('grid')
        ) {
          const availableWidth = (format.width - format.margin.left - format.margin.right) * 96;
          const width = calculateTextWidth({
            text: node.text,
            fontSize: node.fontSize,
            fontFamily: node.fontFamily,
            canvasWidth: availableWidth,
            minWidth: node.width,
          });
          node.width = width;
        }
      }

      // Rectangle corners
      if (selectedShapeId.includes('rect') || selectedShapeId.includes('image')) {
        if (!node.cornerRadius) node.cornerRadius = [0, 0, 0, 0];
        node.cornerRadius[0] = cornerTL;
        node.cornerRadius[1] = cornerTR;
        node.cornerRadius[2] = cornerBR;
        node.cornerRadius[3] = cornerBL;
      }

      // Classic word-list
      if (
        selectedShapeId.includes('classic-word-list') ||
        selectedShapeId.includes('classic-number-list')
      ) {
        node.staticLink.cols = cols;
        node.staticLink.rows = rows;
      }

      // Article word-list
      if (
        selectedShapeId.includes('article-word-list') ||
        selectedShapeId.includes('word-list-block')
      ) {
        node.staticLink.markedWordsStyles.fontStyle = markedWordsFontStyle;
        node.staticLink.markedWordsStyles.textDecoration = markedWordsUnderlineChecked
          ? 'underline'
          : 'none';
        node.staticLink.markedWordsStyles.fill = markedWordsFillColor;
      }

      if (selectedShapeId.includes('grid')) {
        node.staticLink.cols = gridCols;
        node.staticLink.rows = gridRows;
        node.staticLink.smallNumFontSize = smallNumFontSize;
        if (selectedShapeId.includes('word-search') || selectedShapeId.includes('number-search')) {
          node.staticLink.commaSeparatedWords = commaSeparatedWords;
          node.staticLink.difficulty = difficulty;
          node.staticLink.puzzleShape = puzzleShape;
          node.staticLink.nWords = nWords;
          node.staticLink.ringsFillColor = ringsFillColor;
        }
        node.text = gridText;

        node.staticLink.borders.checked1 = gridStroke1Checked;
        node.staticLink.borders.color1 = gridStroke1Color;
        node.staticLink.borders.width1 = gridStroke1Width;

        node.staticLink.borders.checked2 = gridStroke2Checked;
        node.staticLink.borders.color2 = gridStroke2Color;
        node.staticLink.borders.width2 = gridStroke2Width;

        node.staticLink.borders.checked3 = gridStroke3Checked;
        node.staticLink.borders.color3 = gridStroke3Color;
        node.staticLink.borders.width3 = gridStroke3Width;

        // font size of numbers cells in code word map
        node.staticLink.borders.checked4 = gridStroke4Checked;
        node.staticLink.borders.color4 = gridStroke4Color;
        node.staticLink.borders.width4 = gridStroke4Width; // font size

        // background of letters cells in code word map
        node.staticLink.borders.checked5 = gridStroke5Checked;
        node.staticLink.borders.color5 = gridStroke5Color;
      }

      if (selectedShapeId.includes('sudoku-grid')) {
        node.staticLink.grid = sudokuGrid;
      }

      onChange(node, true);
    }
  }, [
    height,
    width,
    x,
    y,
    rotation,
    scaleX,
    scaleY,
    fontFamily,
    fontSize,
    fontStyle,
    textAlign,
    letterSpacing,
    lineHeight,
    opacity,
    fillColor,
    strokeColor,
    strokeWidth,
    cornerTL,
    cornerTR,
    cornerBR,
    cornerBL,
    cols,
    rows,
    commaSeparatedWords,
    difficulty,
    puzzleShape,
    nWords,
    gridCols,
    gridRows,
    gridText,
    gridStroke1Checked,
    gridStroke1Color,
    gridStroke1Width,
    gridStroke2Checked,
    gridStroke2Color,
    gridStroke2Width,
    gridStroke3Checked,
    gridStroke3Color,
    gridStroke3Width,
    gridStroke4Checked,
    gridStroke4Color,
    gridStroke4Width,
    gridStroke5Checked,
    gridStroke5Color,
    ringsFillColor,
    sudokuGrid,
    markedWordsFillColor,
    markedWordsUnderlineChecked,
    markedWordsFontStyle,
    smallNumFontSize,
  ]);

  useEffect(() => {
    if (
      selectedShapeId &&
      (selectedShapeId.includes('classic-word-list') ||
        selectedShapeId.includes('classic-number-list'))
    ) {
      const placeholder = selectedShapeId.includes('classic-word-list')
        ? 'wordgoeshere'
        : '123456789';
      const index = shapes.findIndex(item => item.id === selectedShapeId);
      const node = shapes[index];
      if (node.staticLink && node.staticLink.isAcrossDown)
        node.text = `Across, ${new Array(Math.ceil((rows * cols) / 2) - 1)
          .fill(placeholder)
          .join(', ')}, Down, ${new Array(Math.ceil((rows * cols) / 2) - 1)
          .fill(placeholder)
          .join(', ')}`;
      else node.text = new Array(rows * cols).fill(placeholder).join(', ');
      onChange(node, true);
    }
  }, [rows, cols]);

  return (
    <div className={`${className} form__studio-text`}>
      {!selectedShapeId && (
        <div className="form__studio-heading">{isPuzzleTab ? 'PUZZLES' : 'TEXT'}</div>
      )}
      <div
        style={{
          overflowY: 'auto',
          height: 'calc(100vh - 12.5rem)',
          paddingBottom: 50,
        }}
      >
        <div className={secondarySelectedIds.length >= 1 ? 'd-flex' : 'd-none'}>
          <div className="form-group d-flex vertical-align" style={{ marginRight: '0.8333rem' }}>
            <button className="btn" onClick={() => handleAlignment('col-start')}>
              <IncludeSvg name={'align-side'} />
            </button>
            <button
              className="btn"
              onClick={() => handleAlignment('col-center')}
              style={{ transform: 'rotate(90deg)' }}
            >
              <IncludeSvg name={'align-center-g'} />
            </button>
            <button
              className="btn"
              onClick={() => handleAlignment('col-end')}
              style={{ transform: 'rotate(180deg)' }}
            >
              <IncludeSvg name={'align-side'} />
            </button>
            <button
              disabled={secondarySelectedIds.length < 2 && 'disabled'}
              className="btn"
              onClick={() => handleAlignment('row-space-between')}
              style={{ transform: 'rotate(90deg)' }}
            >
              <IncludeSvg name={'align-center'} />
            </button>
          </div>
          <div className="form-group d-flex horizontal-align">
            <button
              className="btn"
              onClick={() => handleAlignment('row-start')}
              style={{ transform: 'rotate(-90deg)' }}
            >
              <IncludeSvg name={'align-side'} />
            </button>
            <button className="btn" onClick={() => handleAlignment('row-center')}>
              <IncludeSvg name={'align-center-g'} />
            </button>
            <button
              className="btn"
              onClick={() => handleAlignment('row-end')}
              style={{ transform: 'rotate(90deg)' }}
            >
              <IncludeSvg name={'align-side'} />
            </button>
            <button
              disabled={secondarySelectedIds.length < 2 && 'disabled'}
              className="btn"
              onClick={() => handleAlignment('col-space-between')}
            >
              <IncludeSvg name={'align-center'} />
            </button>
          </div>
        </div>

        <div className={`form-group d-flex flex-column ${selectedShapeId ? '' : 'd-none'}`}>
          <div className="form__studio-sub-heading">Size & Position</div>
          <div className="d-flex flex-column ">
            <div className="d-flex mb-2">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mb-2">
                  <label style={{ marginRight: 13 }}>H</label>
                  <InputNumber
                    value={height}
                    setValue={setHeight}
                    className="form-control"
                    type="number"
                    disabled={
                      selectedShapeId &&
                      ((selectedShapeId.includes('text') && !selectedShapeId.includes('story')) ||
                        selectedShapeId.includes('line'))
                    }
                    step={0.01}
                    min={0}
                    onFocus={() => setNoOtherInput(false)}
                    onBlur={() => setNoOtherInput(true)}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <label>W</label>
                  <InputNumber
                    value={width}
                    setValue={setWidth}
                    className="form-control"
                    type="number"
                    step={0.01}
                    min={0}
                    onFocus={() => setNoOtherInput(false)}
                    onBlur={() => setNoOtherInput(true)}
                  />
                </div>
              </div>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minWidth: 50, marginRight: 10 }}
              >
                <CheckboxLock
                  className={'form-check-input'}
                  checked={keepRatio}
                  onChange={() => {
                    setKeepRatio(!keepRatio);
                  }}
                  disabled={isKeepRationDisabled}
                />
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mb-2">
                  <label>X</label>
                  <InputNumber
                    value={Math.round(x)}
                    setValue={setX}
                    className="form-control"
                    type="number"
                    onFocus={() => setNoOtherInput(false)}
                    onBlur={() => setNoOtherInput(true)}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <label>Y</label>
                  <InputNumber
                    value={Math.round(y)}
                    setValue={setY}
                    className="form-control"
                    type="number"
                    onFocus={() => setNoOtherInput(false)}
                    onBlur={() => setNoOtherInput(true)}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-space-between w-100">
              <div className="d-flex  align-items-center mb-2">
                <IncludeSvg name={'rotate'} />
                <InputNumber
                  className="form-control"
                  type="number"
                  value={rotation > -1 ? rotation % 360 : (360 + rotation) % 360}
                  setValue={setRotation}
                  style={{ marginLeft: 6 }}
                  onFocus={() => setNoOtherInput(false)}
                  onBlur={() => setNoOtherInput(true)}
                />
              </div>
              <div className="d-flex" style={{ minWidth: 20 }}></div>
              <div className="d-flex justify-content-center form-control" style={{ maxWidth: 110 }}>
                <button className="btn mx-2" onClick={() => setScaleX(scaleX * -1)}>
                  <IncludeSvg name={'reflect'} />
                </button>
                <button
                  className="btn mx-2"
                  style={{ transform: 'rotate(-90deg)', marginLeft: '0.5rem' }}
                  onClick={() => setScaleY(scaleY * -1)}
                >
                  <IncludeSvg name={'reflect'} />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className={`form-group ${selectedShapeId || isPuzzleTab ? 'd-none' : ''}`}>
          <div className="form__studio-sub-heading">Quick Text</div>
          <div
            className="h4 fw-600 quick-text noselect"
            data-type="heading-text"
            onClick={handleQuickInsertion}
            draggable
            onDragStart={() => {
              setDragEl('heading-text');
            }}
          >
            Add Heading
          </div>
          <div
            className="h5 fw-600 quick-text noselect"
            data-type="subheading-text"
            onClick={handleQuickInsertion}
            draggable
            onDragStart={() => {
              setDragEl('subheading-text');
            }}
          >
            Add Subheading
          </div>
          <div
            className="quick-text noselect"
            data-type="text"
            onClick={handleQuickInsertion}
            draggable
            onDragStart={() => {
              setDragEl('text');
            }}
          >
            Add text to your page
          </div>
        </div>
        <div className={`form-group ${selectedShapeId || isPuzzleTab ? 'd-none' : ''}`}>
          <div className="form__studio-sub-heading">Quick Links</div>
          <div
            className="h4 quick-text noselect"
            data-type="heading-quick-link-text"
            onClick={handleQuickInsertion}
            draggable
            onDragStart={() => {
              setDragEl('heading-quick-link-text');
            }}
          >
            Heading
          </div>
          <div
            className="h4 quick-text noselect"
            data-type="subheading-quick-link-text"
            onClick={handleQuickInsertion}
            draggable
            onDragStart={() => {
              setDragEl('subheading-quick-link-text');
            }}
          >
            Subheading
          </div>
          <div
            className="h4 quick-text noselect"
            data-type="isbn-quick-link-text"
            onClick={handleQuickInsertion}
            draggable
            onDragStart={() => {
              setDragEl('isbn-quick-link-text');
            }}
          >
            ISBN
          </div>
          <div
            className="h4 quick-text noselect"
            data-type="authors-quick-link-text"
            onClick={handleQuickInsertion}
            draggable
            onDragStart={() => {
              setDragEl('authors-quick-link-text');
            }}
          >
            Authors
          </div>
        </div>
        <div className={`form-group ${selectedShapeId || isPuzzleTab ? 'd-none' : ''}`}>
          <div className="form__studio-sub-heading">Quick Numbers</div>
          <div
            className="h4 quick-text noselect"
            data-type="page-number-quick-link-text"
            onClick={handleQuickInsertion}
            draggable
            onDragStart={() => {
              setDragEl('page-number-quick-link-text');
            }}
          >
            Page Number
          </div>
          <div
            className="h4 quick-text noselect"
            data-type="problem-number-quick-link-text"
            onClick={handleQuickInsertion}
            draggable
            onDragStart={() => {
              setDragEl('problem-number-quick-link-text');
            }}
          >
            Problem Number
          </div>
          <div
            className="h4 quick-text noselect"
            data-type="solution-number-quick-link-text"
            onClick={handleQuickInsertion}
            draggable
            onDragStart={() => {
              setDragEl('solution-number-quick-link-text');
            }}
          >
            Solution Number
          </div>
        </div>

        <div className={`form-group ${selectedShapeId || isPuzzleTab ? 'd-none' : ''}`}>
          <div className="form__studio-sub-heading">Table of Contents</div>
          <div
            className="h4 quick-text noselect"
            data-type="classic-table-of-contents-text"
            onClick={handleQuickInsertion}
            draggable
            onDragStart={() => {
              setDragEl('classic-table-of-contents-text');
            }}
          >
            Classic Table of Contents
          </div>
        </div>

        <div className={`form-group ${selectedShapeId || isPuzzleTab ? 'd-none' : ''}`}>
          <div className="form__studio-sub-heading">Coloring Books</div>
          <div
            className="h4 quick-text noselect"
            data-type="coloring-book-image"
            onClick={handleQuickInsertion}
            draggable
            onDragStart={() => {
              setDragEl('coloring-book-image');
            }}
          >
            Image
          </div>
        </div>

        {/* New Implementation */}

        <div className={` form-group ${selectedShapeId || !isPuzzleTab ? 'd-none' : ''}`}>
          <div className="form__studio-sub-heading">Select Content Type</div>

          <div className="d-flex gap-3">
            <SelectWrapper
              className="form-control"
              value={contentType}
              setValue={setContentType}
              options={content_types_options.map(item => ({ label: item.text, value: item.value }))}
              isFonts={true}
            />
          </div>
        </div>

        {/* The following section is for word_search controllers */}
        <div
          className={`form-group ${
            selectedShapeId || !isPuzzleTab || contentType !== 'word_search' ? 'd-none' : ''
          }`}
        >
          <div className="form__studio-sub-heading">Word Search</div>
          <div className="d-flex gap-3">
            <div
              className="alphaCard h5 fw-600 quick-text noselect w-100 left"
              data-type="word-search-grid - puzzle"
              onClick={handleQuickInsertion}
              draggable
              onDragStart={() => {
                setDragEl('word-search-grid - puzzle');
              }}
            >
              <div className="AlphaIcon">
                <LetterQ />
              </div>{' '}
              Problem Puzzle
            </div>
          </div>
          <div className="d-flex gap-3">
            <div
              className="alphaCard h5 fw-600 quick-text noselect w-100 left"
              data-type="word-search-grid - solution"
              onClick={handleQuickInsertion}
              draggable
              onDragStart={() => {
                setDragEl('word-search-grid - solution');
              }}
            >
              <div className="AlphaIcon">
                <LetterA />
              </div>{' '}
              Solution Puzzle
            </div>
          </div>
        </div>

        <div
          className={`form-group ${
            selectedShapeId || !isPuzzleTab || contentType !== 'word_search' ? 'd-none' : ''
          }`}
        >
          <div className="form__studio-sub-heading ">Word List</div>

          <div className="d-flex gap-3">
            <div
              className="alphaCard h5 fw-600 quick-text noselect w-100 left"
              data-type="classic-word-list-text"
              onClick={handleQuickInsertion}
              draggable
              onDragStart={() => {
                setDragEl('classic-word-list-text');
              }}
            >
              <div className="AlphaIcon">
                <LetterC />
              </div>{' '}
              Classic List
            </div>
          </div>
          <div className="d-flex gap-3">
            <div
              className="alphaCard h5 fw-600 quick-text noselect w-100 left"
              data-type="smart-word-list-text"
              onClick={handleQuickInsertion}
              draggable
              onDragStart={() => {
                setDragEl('smart-word-list-text');
              }}
            >
              <div className="AlphaIcon">
                <LetterS />
              </div>{' '}
              Smart List
            </div>
          </div>
        </div>
        <div
          className={`form-group ${
            selectedShapeId || !isPuzzleTab || contentType !== 'word_search' ? 'd-none' : ''
          }`}
        >
          <div className="form__studio-sub-heading ">Articles</div>
          <div className="d-flex gap-3">
            <div
              className="alphaCard h5 fw-600 quick-text noselect w-100 left"
              data-type="article-title-text"
              onClick={handleQuickInsertion}
              draggable
              onDragStart={() => {
                setDragEl('article-title-text');
              }}
            >
              <div className="AlphaIcon">
                <LetterA />
              </div>
              {''}
              Article Title
            </div>
          </div>
          <div className="d-flex gap-3">
            <div
              className="alphaCard h5 fw-600 quick-text noselect w-100 left"
              data-type="article-word-list-text"
              onClick={handleQuickInsertion}
              draggable
              onDragStart={() => {
                setDragEl('article-word-list-text');
              }}
            >
              <div className="AlphaIcon">
                <LetterA />
              </div>
              {''}
              Article Contnet
            </div>
          </div>
        </div>
        {/* The following section is for number_search controllers */}
        <div
          className={`form-group ${
            selectedShapeId || !isPuzzleTab || contentType !== 'number_search' ? 'd-none' : ''
          }`}
        >
          <div className="form__studio-sub-heading">Number Search</div>
          <div className="d-flex gap-3">
            <div
              className="alphaCard h5 fw-600 quick-text noselect w-100 left"
              data-type="number-search-grid - puzzle"
              onClick={handleQuickInsertion}
              draggable
              onDragStart={() => {
                setDragEl('number-search-grid - puzzle');
              }}
            >
              <div className="AlphaIcon">
                <LetterQ />
              </div>{' '}
              Problem Puzzle
            </div>
          </div>
          <div className="d-flex gap-3">
            <div
              className="alphaCard h5 fw-600 quick-text noselect w-100 left"
              data-type="number-search-grid - solution"
              onClick={handleQuickInsertion}
              draggable
              onDragStart={() => {
                setDragEl('number-search-grid - solution');
              }}
            >
              <div className="AlphaIcon">
                <LetterA />
              </div>{' '}
              Solution Puzzle
            </div>
          </div>
        </div>
        <div
          className={`form-group ${
            selectedShapeId || !isPuzzleTab || contentType !== 'number_search' ? 'd-none' : ''
          }`}
        >
          <div className="form__studio-sub-heading ">Number List</div>

          <div className="d-flex gap-3">
            <div
              className="alphaCard h5 fw-600 quick-text noselect w-100 left"
              data-type="classic-number-list-text"
              onClick={handleQuickInsertion}
              draggable
              onDragStart={() => {
                setDragEl('classic-number-list-text');
              }}
            >
              <div className="AlphaIcon">
                <LetterC />
              </div>{' '}
              &nbsp; Classic List
            </div>
          </div>
          <div className="d-flex gap-3">
            <div
              className="alphaCard h5 fw-600 quick-text noselect w-100 left"
              data-type="smart-number-list-text"
              onClick={handleQuickInsertion}
              draggable
              onDragStart={() => {
                setDragEl('smart-number-list-text');
              }}
            >
              <div className="AlphaIcon">
                <LetterS />
              </div>{' '}
              Smart List
            </div>
          </div>
        </div>

        {/* The following section is for Sudoku controllers */}
        <div
          className={`form-group ${
            selectedShapeId || !isPuzzleTab || contentType !== 'sudoku' ? 'd-none' : ''
          }`}
        >
          <div className="form__studio-sub-heading">Sudoku</div>

          <div className="d-flex gap-3 mt-2">
            <div
              className="alphaCard h5 fw-600 quick-text noselect w-100 center"
              data-type="sudoku-grid - puzzle"
              onClick={handleQuickInsertion}
              draggable
              onDragStart={() => {
                setDragEl('sudoku-grid - puzzle');
              }}
            >
              <div className="AlphaIcon sudoku-icon1 ">
                <LetterQ />
              </div>{' '}
              Sudoku Puzzle
            </div>
          </div>
          <div className="d-flex gap-3 mt-2">
            <div
              className="alphaCard h5 fw-600 quick-text noselect w-100 center"
              data-type="sudoku-grid - solution"
              onClick={handleQuickInsertion}
              draggable
              onDragStart={() => {
                setDragEl('sudoku-grid - solution');
              }}
            >
              <div className="AlphaIcon sudoku-icon ">
                <LetterA />
              </div>{' '}
              Sudoku Solution
            </div>
          </div>
        </div>

        {/* The following section is for cross_words controllers */}
        <div
          className={`form-group ${
            selectedShapeId || !isPuzzleTab || contentType !== 'cross_words' ? 'd-none' : ''
          }`}
        >
          <div className="form__studio-sub-heading">Corss Words</div>

          <div className="d-flex gap-3 mt-2">
            <div
              className="alphaCard h5 fw-600 quick-text noselect w-100 center"
              data-type="cross-words-grid - puzzle"
              onClick={handleQuickInsertion}
              draggable
              onDragStart={() => {
                setDragEl('cross-words-grid - puzzle');
              }}
            >
              <div className="AlphaIcon cross-words-icon1 ">
                <LetterQ />
              </div>{' '}
              Cross-Words Puzzle
            </div>
          </div>
          <div className="d-flex gap-3">
            <div
              className="alphaCard h5 fw-600 quick-text noselect w-100 center"
              data-type="cross-words-grid - solution"
              onClick={handleQuickInsertion}
              draggable
              onDragStart={() => {
                setDragEl('cross-words-grid - solution');
              }}
            >
              <div className="AlphaIcon cross-words-icon ">
                <LetterA />
              </div>{' '}
              Cross-Words Solution
            </div>
          </div>
          <div className="d-flex gap-3">
            <div
              className="alphaCard h5 fw-600 quick-text noselect w-100 left"
              data-type="word-list-block-text"
              onClick={handleQuickInsertion}
              draggable
              onDragStart={() => {
                setDragEl('word-list-block-text');
              }}
            >
              <div className="AlphaIcon">
                <LetterC />
              </div>{' '}
              Hints List (block)
            </div>
          </div>
        </div>

        {/* The following section is for code word controllers */}
        <div
          className={`form-group ${
            selectedShapeId || !isPuzzleTab || contentType !== 'code_word' ? 'd-none' : ''
          }`}
        >
          <div className="form__studio-sub-heading">Code Word</div>

          <div className="d-flex gap-3 mt-2">
            <div
              className="alphaCard h5 fw-600 quick-text noselect w-100 center"
              data-type="code-word-grid - puzzle"
              onClick={handleQuickInsertion}
              draggable
              onDragStart={() => {
                setDragEl('code-word-grid - puzzle');
              }}
            >
              <div className="AlphaIcon code-word-icon1 ">
                <LetterQ />
              </div>{' '}
              Code Word Puzzle
            </div>
          </div>
          <div className="d-flex gap-3">
            <div
              className="alphaCard h5 fw-600 quick-text noselect w-100 center"
              data-type="code-word-grid - solution"
              onClick={handleQuickInsertion}
              draggable
              onDragStart={() => {
                setDragEl('code-word-grid - solution');
              }}
            >
              <div className="AlphaIcon code-word-icon ">
                <LetterA />
              </div>{' '}
              Code Word Solution
            </div>
          </div>
        </div>
        <div
          className={`form-group ${
            selectedShapeId || !isPuzzleTab || contentType !== 'code_word' ? 'd-none' : ''
          }`}
        >
          <div className="form__studio-sub-heading">Alphabets</div>
          <div className="d-flex gap-3">
            <div
              className="alphaCard h5 fw-600 quick-text noselect w-100 center"
              data-type="alphabets-hz-grid"
              onClick={handleQuickInsertion}
              draggable
              onDragStart={() => {
                setDragEl('alphabets-hz-grid');
              }}
            >
              Alphabets HZ
            </div>
          </div>
          <div className="d-flex gap-3">
            <div
              className="alphaCard h5 fw-600 quick-text noselect w-100 center"
              data-type="alphabets-vt-grid"
              onClick={handleQuickInsertion}
              draggable
              onDragStart={() => {
                setDragEl('alphabets-vt-grid');
              }}
            >
              Alphabets VT
            </div>
          </div>
        </div>
        <div
          className={`form-group ${
            selectedShapeId || !isPuzzleTab || contentType !== 'code_word' ? 'd-none' : ''
          }`}
        >
          <div className="form__studio-sub-heading">Maps</div>

          <div className="d-flex gap-3">
            <div
              className="alphaCard h5 fw-600 quick-text noselect w-100 center"
              data-type="map-hz-grid"
              onClick={handleQuickInsertion}
              draggable
              onDragStart={() => {
                setDragEl('map-hz-grid');
              }}
            >
              Map HZ
            </div>
          </div>
          <div className="d-flex gap-3">
            <div
              className="alphaCard h5 fw-600 quick-text noselect w-100 center"
              data-type="map-vt-grid"
              onClick={handleQuickInsertion}
              draggable
              onDragStart={() => {
                setDragEl('map-vt-grid');
              }}
            >
              Map VT
            </div>
          </div>
        </div>

        {/* The following section is for trivia controllers */}
        <div
          className={`form-group ${
            selectedShapeId || !isPuzzleTab || contentType !== 'trivia' ? 'd-none' : ''
          }`}
        >
          <div className="form__studio-sub-heading">Trivia</div>
          <div className="d-flex gap-3">
            <div
              className="alphaCard h5 fw-600 quick-text noselect w-100 left"
              data-type="trivia-question-text"
              onClick={handleQuickInsertion}
              draggable
              onDragStart={() => {
                setDragEl('trivia-question-text');
              }}
            >
              <div className="AlphaIcon">
                <LetterQ />
              </div>{' '}
              Trivia Question
            </div>
          </div>
          <div className="d-flex gap-3">
            <div
              className="alphaCard h5 fw-600 quick-text noselect w-100 left"
              data-type="trivia-answer-text"
              onClick={handleQuickInsertion}
              draggable
              onDragStart={() => {
                setDragEl('trivia-answer-text');
              }}
            >
              <div className="AlphaIcon">
                <LetterA />
              </div>{' '}
              Trivia Answer
            </div>
          </div>
        </div>

        {/* The following section is for story controllers */}
        <div
          className={`form-group ${
            selectedShapeId || !isPuzzleTab || contentType !== 'story' ? 'd-none' : ''
          }`}
        >
          <div className="form__studio-sub-heading">Story</div>
          <div
            className="alphaCard h5 fw-600 quick-text noselect w-100 left"
            data-type="story-title-text"
            onClick={handleQuickInsertion}
            draggable
            onDragStart={() => {
              setDragEl('story-title-text');
            }}
          >
            <div className="AlphaIcon">
              <LetterS />
            </div>
            Story Title
          </div>

          <div
            className="alphaCard h5 fw-600 quick-text noselect w-100 left"
            data-type="story-word-list-text"
            onClick={handleQuickInsertion}
            draggable
            onDragStart={() => {
              setDragEl('story-word-list-text');
            }}
          >
            <div className="AlphaIcon">
              <LetterS />
            </div>
            Story Content
          </div>
        </div>

        <div
          className={`form-group ${
            selectedShapeId &&
            (selectedShapeId.includes('text') || selectedShapeId.includes('grid'))
              ? ''
              : 'd-none'
          }`}
        >
          <div className="form__studio-sub-heading">Text</div>
          <div className="d-flex align-items-center mb-3">
            <label>Font</label>
            <SelectWrapper
              className="form-control"
              value={fontFamily}
              setValue={setFontFamily}
              options={fontFamilies.map(item => ({ label: item, value: item }))}
              onFocus={() => setNoOtherInput(false)}
              onBlur={() => setNoOtherInput(true)}
              isFonts={true}
            />
          </div>
          <div className="d-flex align-items-center mb-3">
            <div className="d-flex w-100 align-items-center">
              <label>Size</label>
              <InputNumber
                className="form-control"
                value={fontSize}
                setValue={setFontSize}
                min={6}
                onFocus={() => {
                  setNoOtherInput(false);
                  setFontSizeInputChanging(true);
                }}
                onBlur={() => {
                  setNoOtherInput(true);
                  setFontSizeInputChanging(false);
                }}
              />
            </div>

            <div className="d-flex w-100 align-items-center" style={{ marginLeft: 10 }}>
              <label>Style</label>
              <SelectWrapper
                className="form-control"
                value={fontStyle}
                setValue={setFontStyle}
                options={FONT_STYLES_OPTS}
                onFocus={() => setNoOtherInput(false)}
                onBlur={() => setNoOtherInput(true)}
              />
            </div>
          </div>
          <div
            className={selectedShapeId && !selectedShapeId.includes('grid') ? 'd-flex' : 'd-none'}
          >
            <div className="form-control w-25">
              <button
                className="btn"
                onClick={() => {
                  if (textAlign === 'left') setTextAlign('center');
                  if (textAlign === 'center') setTextAlign('right');
                  if (textAlign === 'right') setTextAlign('justify');
                  if (textAlign === 'justify') setTextAlign('left');
                }}
              >
                <IncludeSvg name={`align-text-${textAlign}`} />
              </button>
            </div>
            <div className="d-flex align-items-center">
              <span className="mx-2">
                <IncludeSvg name={'va'} />
              </span>
              <InputNumber
                className="form-control"
                value={letterSpacing}
                setValue={setLetterSpacing}
                min={0}
                onFocus={() => setNoOtherInput(false)}
                onBlur={() => setNoOtherInput(true)}
                disabled={selectedShapeId && selectedShapeId.includes('table')}
              />
              {/* <input className="form-control" type="number" defaultValue="12" /> */}
            </div>
            <div className="d-flex align-items-center">
              <span className="mx-2">
                <IncludeSvg name={'line-spacing'} />
              </span>
              <InputNumber
                className="form-control"
                value={lineHeight}
                setValue={setLineHeight}
                min={1}
                step={0.1}
                onFocus={() => setNoOtherInput(false)}
                onBlur={() => setNoOtherInput(true)}
              />
              {/* <input className="form-control" type="number" defaultValue="12" /> */}
            </div>
            {/* <div className="d-flex align-items-center">
              <span className="mx-2">
                <IncludeSvg name={'return-line-spacing'} />
              </span>
              <input className="form-control" type="number" defaultValue="12" />
            </div> */}
          </div>
        </div>
        <div className={`form-group ${selectedShapeId ? '' : 'd-none'}`}>
          <div className="form__studio-sub-heading">Appearance</div>
          <div className="d-flex align-items-center mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              checked={opacityChecked}
              onChange={() => setOpacityChecked(!opacityChecked)}
            />
            <InputRange value={opacity} setValue={value => setOpacity(value)} />
          </div>
          {selectedShapeId &&
            !selectedShapeId.includes('image') &&
            !selectedShapeId.includes('line') && (
              <div className="d-flex align-items-center mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={fillColorChecked}
                  onChange={() => setFillColorChecked(!fillColorChecked)}
                />
                <input
                  className="form-control w-25 mx-3"
                  type="color"
                  value={fillColor}
                  onChange={e => setFillColor(e.target.value)}
                />
                <label style={{ width: 50 }}>Color</label>
                <button className="btn" style={{ marginLeft: 'auto' }}>
                  <IncludeSvg name={'color-picker'} />
                </button>
              </div>
            )}
          {selectedShapeId &&
            !selectedShapeId.includes('table') &&
            BorderControls(
              strokeChecked,
              setStrokeChecked,
              strokeColor,
              setStrokeColor,
              selectedShapeId,
              strokeWidth,
              setStrokeWidth,
              setNoOtherInput
            )}
        </div>
        <div
          className={`form-group ${
            selectedShapeId &&
            (selectedShapeId.includes('classic-word-list') ||
              selectedShapeId.includes('classic-number-list'))
              ? ''
              : 'd-none'
          }`}
        >
          <div className="form__studio-sub-heading">Word-list settings</div>
          <div className="d-flex align-items-center mb-2">
            <label style={{ marginRight: 21 }}>Cols</label>
            <InputNumber
              value={cols}
              setValue={setCols}
              className="form-control"
              type="number"
              step={1}
              min={1}
              onFocus={() => setNoOtherInput(false)}
              onBlur={() => setNoOtherInput(true)}
            />
          </div>
          <div className="d-flex align-items-center mb-2">
            <label style={{ marginRight: 13 }}>Rows</label>
            <InputNumber
              value={rows}
              setValue={setRows}
              className="form-control"
              type="number"
              step={1}
              min={1}
              onFocus={() => setNoOtherInput(false)}
              onBlur={() => setNoOtherInput(true)}
            />
          </div>
        </div>
        <div
          className={`form-group ${
            selectedShapeId && selectedShapeId.includes('grid') ? '' : 'd-none'
          }`}
        >
          <div className="form__studio-sub-heading">Grid settings</div>
          {selectedShapeId && !selectedShapeId.includes('sudoku-grid') ? (
            <div
              className={
                ['hz', 'vt', 'cross-words', 'code-word'].some(item =>
                  selectedShapeId.includes(item)
                )
                  ? 'd-none'
                  : ''
              }
            >
              <div className="d-flex align-items-center mb-2">
                <label className="grid-settings" style={{ marginRight: 13 }}>
                  Rows
                </label>
                <InputNumber
                  value={gridRows}
                  setValue={v => {
                    let value = v;
                    if (
                      WORDSEARCH_DESIGN[puzzleShape] &&
                      WORDSEARCH_DESIGN[puzzleShape].odd &&
                      v % 2 === 0
                    )
                      value = v + 1;

                    setGridRows(value);
                    if (WORDSEARCH_DESIGN[puzzleShape] && WORDSEARCH_DESIGN[puzzleShape].xEqY)
                      setGridCols(value);
                  }}
                  className="form-control"
                  type="number"
                  step={
                    WORDSEARCH_DESIGN[puzzleShape] && WORDSEARCH_DESIGN[puzzleShape].odd ? 2 : 1
                  }
                  min={WORDSEARCH_DESIGN[puzzleShape] ? WORDSEARCH_DESIGN[puzzleShape].min : 1}
                  onFocus={() => setNoOtherInput(false)}
                  onBlur={() => setNoOtherInput(true)}
                />
              </div>
              <div className="d-flex align-items-center mb-2 grid-settings">
                <label className="grid-settings" style={{ marginRight: 13 }}>
                  Columns
                </label>
                <InputNumber
                  value={gridCols}
                  setValue={v => {
                    let value = v;
                    if (
                      WORDSEARCH_DESIGN[puzzleShape] &&
                      WORDSEARCH_DESIGN[puzzleShape].odd &&
                      v % 2 === 0
                    )
                      value = v + 1;

                    setGridCols(value);
                    if (WORDSEARCH_DESIGN[puzzleShape] && WORDSEARCH_DESIGN[puzzleShape].xEqY)
                      setGridRows(value);
                  }}
                  className="form-control"
                  type="number"
                  step={
                    WORDSEARCH_DESIGN[puzzleShape] && WORDSEARCH_DESIGN[puzzleShape].odd ? 2 : 1
                  }
                  min={WORDSEARCH_DESIGN[puzzleShape] ? WORDSEARCH_DESIGN[puzzleShape].min : 1}
                  onFocus={() => setNoOtherInput(false)}
                  onBlur={() => setNoOtherInput(true)}
                />
              </div>
              <div className="d-flex align-items-center mb-2 grid-settings">
                <label className={'grid-settings'} style={{ marginRight: 13 }}>
                  Small Size
                </label>
                <InputNumber
                  value={smallNumFontSize}
                  setValue={setSmallNumFontSize}
                  className="form-control"
                  type="number"
                  step={1}
                  min={6}
                  onFocus={() => setNoOtherInput(false)}
                  onBlur={() => setNoOtherInput(true)}
                />
              </div>
            </div>
          ) : (
            <div>
              <div className="d-flex align-items-center mb-2">
                <label className="grid-settings">Size</label>
                <SelectWrapper
                  className="form-control"
                  value={sudokuGrid}
                  setValue={setSudokuGrid}
                  options={SUDOKU_GRIDS.map(item => {
                    return {
                      value: item,
                      label: `${item.size[0]} X ${item.size[1]}`,
                    };
                  })}
                  onFocus={() => setNoOtherInput(false)}
                  onBlur={() => setNoOtherInput(true)}
                />
              </div>
            </div>
          )}

          {selectedShapeId && selectedShapeId.includes('cross-words') && (
            <div>
              <div className="d-flex align-items-center mb-2">
                <label className="grid-settings">Size</label>
                <SelectWrapper
                  className="form-control"
                  value={gridRows}
                  setValue={val => {
                    setGridRows(val);
                    setGridCols(val);
                  }}
                  options={CROSSWORDS_DESIGN.normal.sizes.map(size => {
                    return {
                      value: size,
                      label: `${size} X ${size}`,
                    };
                  })}
                  onFocus={() => setNoOtherInput(false)}
                  onBlur={() => setNoOtherInput(true)}
                />
              </div>
              <div className="d-flex align-items-center mb-2 grid-settings">
                <label className={'grid-settings'} style={{ marginRight: 13 }}>
                  Small Size
                </label>
                <InputNumber
                  value={smallNumFontSize}
                  setValue={setSmallNumFontSize}
                  className="form-control"
                  type="number"
                  step={1}
                  min={6}
                  onFocus={() => setNoOtherInput(false)}
                  onBlur={() => setNoOtherInput(true)}
                />
              </div>
            </div>
          )}
          {selectedShapeId && selectedShapeId.includes('code-word') && (
            <div>
              <div className="d-flex align-items-center mb-2">
                <label className="grid-settings">Size</label>
                <SelectWrapper
                  className="form-control"
                  value={gridRows}
                  setValue={val => {
                    setGridRows(val);
                    setGridCols(val);
                  }}
                  options={CODEWORD_DESIGN.normal.sizes.map(size => {
                    return {
                      value: size,
                      label: `${size} X ${size}`,
                    };
                  })}
                  onFocus={() => setNoOtherInput(false)}
                  onBlur={() => setNoOtherInput(true)}
                />
              </div>
              <div className="d-flex align-items-center mb-2 grid-settings">
                <label className={'grid-settings'} style={{ marginRight: 13 }}>
                  Small Size
                </label>
                <InputNumber
                  value={smallNumFontSize}
                  setValue={setSmallNumFontSize}
                  className="form-control"
                  type="number"
                  step={1}
                  min={6}
                  onFocus={() => setNoOtherInput(false)}
                  onBlur={() => setNoOtherInput(true)}
                />
              </div>
            </div>
          )}

          {selectedShapeId &&
            (selectedShapeId.includes('word-search') ||
              selectedShapeId.includes('number-search')) && (
              <>
                {selectedShapeId.includes('word-search') && (
                  <div className="d-flex align-items-center mb-2">
                    <label className="grid-settings" style={{ marginRight: 13 }}>
                      Words
                    </label>
                    <textarea
                      className="form-control"
                      style={{ height: 80 }}
                      value={commaSeparatedWords}
                      onChange={e => setCommaSeparatedWords(e.target.value)}
                    ></textarea>
                  </div>
                )}
                <div className="d-flex align-items-center mb-2">
                  <label className="grid-settings" style={{ marginRight: 13 }}>
                    Difficulty
                  </label>
                  <SelectWrapper
                    className="form-control"
                    value={difficulty}
                    setValue={setDifficulty}
                    options={DIFFICULTY_OPTS}
                    onFocus={() => setNoOtherInput(false)}
                    onBlur={() => setNoOtherInput(true)}
                  />
                </div>
                <div className="d-flex align-items-center mb-2">
                  <label className="grid-settings" style={{ marginRight: 13 }}>
                    Shape
                  </label>
                  <SelectWrapper
                    className="form-control"
                    value={puzzleShape}
                    setValue={setPuzzleShape}
                    options={PUZZLE_SHAPE_OPTS}
                    onFocus={() => setNoOtherInput(false)}
                    onBlur={() => setNoOtherInput(true)}
                  />
                </div>
                <div className="d-flex align-items-center mb-2">
                  <label className="grid-settings" style={{ marginRight: 13 }}>
                    nWords
                  </label>
                  <InputNumber
                    value={nWords}
                    setValue={setNWords}
                    className="form-control"
                    type="number"
                    step={1}
                    min={1}
                    onFocus={() => setNoOtherInput(false)}
                    onBlur={() => setNoOtherInput(true)}
                  />
                </div>
              </>
            )}

          <hr
            className={
              selectedShapeId?.includes('hz') || selectedShapeId?.includes('vt') ? 'd-none' : ''
            }
          ></hr>

          {BorderControls(
            gridStroke1Checked,
            setGridStroke1Checked,
            gridStroke1Color,
            setGridStroke1Color,
            selectedShapeId,
            gridStroke1Width,
            setGridStroke1Width,
            setNoOtherInput
          )}
          <div className="mt-2"></div>

          {selectedShapeId &&
            !selectedShapeId.includes('word-search-grid - puzzle') &&
            !selectedShapeId.includes('table') &&
            BorderControls(
              gridStroke2Checked,
              setGridStroke2Checked,
              gridStroke2Color,
              setGridStroke2Color,
              selectedShapeId,
              gridStroke2Width,
              setGridStroke2Width,
              setNoOtherInput,
              true
            )}

          {/* Numbers font size & color */}
          {selectedShapeId &&
            (selectedShapeId.includes('map-hz') || selectedShapeId.includes('map-vt')) &&
            BorderControls(
              gridStroke4Checked,
              setGridStroke4Checked,
              gridStroke4Color,
              setGridStroke4Color,
              selectedShapeId,
              gridStroke4Width,
              setGridStroke4Width,
              setNoOtherInput,
              false,
              !selectedShapeId.includes('alphabets'),
              selectedShapeId.includes('alphabets'),
              true
            )}

          {/* Map & Alpha either slash props or numbers background*/}
          {selectedShapeId &&
            (selectedShapeId.includes('hz') || selectedShapeId.includes('vt')) &&
            BorderControls(
              gridStroke3Checked,
              setGridStroke3Checked,
              gridStroke3Color,
              setGridStroke3Color,
              selectedShapeId,
              gridStroke3Width,
              setGridStroke3Width,
              setNoOtherInput,
              false,
              !selectedShapeId.includes('alphabets'),
              selectedShapeId.includes('alphabets')
            )}

          {/* Borders of Map & Alpha */}
          {selectedShapeId &&
            (selectedShapeId.includes('map-hz') || selectedShapeId.includes('map-vt')) &&
            BorderControls(
              gridStroke5Checked,
              setGridStroke5Checked,
              gridStroke5Color,
              setGridStroke5Color,
              selectedShapeId,
              undefined,
              undefined,
              setNoOtherInput,
              false,
              !selectedShapeId.includes('alphabets'),
              selectedShapeId.includes('alphabets'),
              false
            )}

          {/* Word Search Rings */}
          {selectedShapeId &&
            selectedShapeId.includes('word-search-grid') &&
            !selectedShapeId.includes('puzzle') && (
              <div className="d-flex align-items-center mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={ringsFillColorChecked}
                  onChange={() => setRingsFillColorChecked(!ringsFillColorChecked)}
                />
                <input
                  className="form-control w-25 mx-3"
                  type="color"
                  value={ringsFillColor}
                  onChange={e => setRingsFillColor(e.target.value)}
                />
                <label style={{ width: 50 }}>Fill</label>
                <button className="btn" style={{ marginLeft: 'auto' }}>
                  <IncludeSvg name={'color-picker'} />
                </button>
              </div>
            )}
        </div>

        {selectedShapeId &&
          (selectedShapeId.includes('rect') || selectedShapeId.includes('image')) && (
            <div className="d-flex align-items-end mt-2">
              <button
                style={{ height: 35, width: 35, marginRight: 5 }}
                className={`btn form-control ${cornerRadiusEq ? 'active-border' : ''}`}
                onClick={() => {
                  setCornerRadiusEq(true);
                  setCornerTR(cornerTL);
                  setCornerBR(cornerTL);
                  setCornerBL(cornerTL);
                }}
              >
                <IncludeSvg name={'corners-eq'} />
              </button>
              <button
                style={{ height: 35, width: 35, marginRight: 5 }}
                className={`btn form-control ${!cornerRadiusEq ? 'active-border' : ''}`}
                onClick={() => {
                  setCornerRadiusEq(false);
                }}
              >
                <IncludeSvg name={'corners-not-eq'} />
              </button>
              {cornerRadiusEq && (
                <InputNumber
                  className="form-control mx-1 underline"
                  value={cornerTL}
                  setValue={val => {
                    setCornerTL(val);
                    setCornerTR(val);
                    setCornerBR(val);
                    setCornerBL(val);
                  }}
                  min={0}
                  hideArxq3wdzrs={true}
                  onFocus={() => setNoOtherInput(false)}
                  onBlur={() => setNoOtherInput(true)}
                />
              )}
              {!cornerRadiusEq && (
                <>
                  <InputNumber
                    className="form-control mx-2 underline"
                    value={cornerTL}
                    setValue={setCornerTL}
                    min={0}
                    // hideArrows={true}
                    onFocus={() => setNoOtherInput(false)}
                    onBlur={() => setNoOtherInput(true)}
                  />
                  <InputNumber
                    className="form-control mx-2 underline"
                    value={cornerTR}
                    setValue={setCornerTR}
                    min={0}
                    // hideArrows={true}
                    onFocus={() => setNoOtherInput(false)}
                    onBlur={() => setNoOtherInput(true)}
                  />
                  <InputNumber
                    className="form-control mx-2 underline"
                    value={cornerBR}
                    setValue={setCornerBR}
                    min={0}
                    // hideArrows={true}
                    onFocus={() => setNoOtherInput(false)}
                    onBlur={() => setNoOtherInput(true)}
                  />
                  <InputNumber
                    className="form-control mx-2 underline"
                    value={cornerBL}
                    setValue={setCornerBL}
                    min={0}
                    // hideArrows={true}
                    onFocus={() => setNoOtherInput(false)}
                    onBlur={() => setNoOtherInput(true)}
                  />
                </>
              )}
            </div>
          )}

        {selectedShapeId &&
          (selectedShapeId.includes('article-word-list') ||
            selectedShapeId.includes('word-list-block')) && (
            <div className="form-group">
              <div className="form__studio-sub-heading">Marked words</div>
              <div className="d-flex">
                <div className="d-flex w-75 mb-3 align-items-center">
                  <input
                    className="form-control "
                    type="color"
                    value={markedWordsFillColor}
                    onChange={e => setMarkedWordsFillColor(e.target.value)}
                  />
                  <label htmlFor="marked-words-underline" className="mx-3">
                    Color
                  </label>
                </div>
                <div className="d-flex align-items-start mx-4 pt-2">
                  <input
                    id="marked-words-underline"
                    className="form-check-input"
                    type="checkbox"
                    checked={markedWordsUnderlineChecked}
                    onChange={() => setMarkedWordsUnderlineChecked(!markedWordsUnderlineChecked)}
                  />
                  <label htmlFor="marked-words-underline" className="mx-3">
                    Underline
                  </label>
                </div>
              </div>
              <div className="d-flex w-100 align-items-center">
                <label>Style</label>
                <SelectWrapper
                  className="form-control"
                  value={markedWordsFontStyle}
                  setValue={setMarkedWordsFontStyle}
                  options={FONT_STYLES_OPTS}
                  onFocus={() => setNoOtherInput(false)}
                  onBlur={() => setNoOtherInput(true)}
                />
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

export default FormatStudioFormText;

function BorderControls(
  strokeChecked,
  setStrokeChecked,
  strokeColor,
  setStrokeColor,
  selectedShapeId,
  strokeWidth,
  setStrokeWidth,
  setNoOtherInput,
  isInner = false,
  isMap = false,
  isAlpha = false,
  isFontSize = false
) {
  return (
    <div className="d-flex align-items-center">
      <input
        className="form-check-input"
        type="checkbox"
        checked={strokeChecked}
        onChange={() => {
          setStrokeChecked(!strokeChecked);
        }}
      />
      <input
        type="color"
        className="form-control w-25 mx-3"
        value={strokeColor}
        onChange={e => setStrokeColor(e.target.value)}
      />
      <label style={{ width: 50 }}>
        {selectedShapeId &&
          (selectedShapeId.includes('line')
            ? 'Stroke'
            : isMap && !setStrokeWidth
            ? 'Alpha Bg'
            : isMap && !isFontSize
            ? 'Num Bg'
            : isMap && isFontSize
            ? 'Nums'
            : isAlpha
            ? 'Slash'
            : !isInner
            ? 'Border'
            : selectedShapeId.includes('word-search')
            ? 'Rings'
            : 'Inner border')}
      </label>
      {(!isMap || isFontSize) && strokeWidth && setStrokeWidth && (
        <span style={{ maxWidth: 70 }}>
          <InputNumber
            className="form-control"
            value={strokeWidth}
            setValue={setStrokeWidth}
            min={isMap ? 6 : 0.1}
            step={isMap ? 1 : 0.1}
            onFocus={() => setNoOtherInput(false)}
            onBlur={() => setNoOtherInput(true)}
          />
        </span>
      )}
      <button className="btn" style={{ marginLeft: 'auto' }}>
        <IncludeSvg name={'color-picker'} />
      </button>
    </div>
  );
}
