// https://react-select.com/home

import { useState, useRef, useEffect } from 'react';
import { colors } from 'settings/colors';
import IncludeSvg from 'components/common/IncludeSvg';
import './SelectWrapper.scss';

import Select, { components } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';

const customStyles = ({ isFonts, controllerBGColor }) => ({
  control: styles => ({
    ...styles,
    fontSize: '1.3333rem',
    fontFamily: 'Open Sans',
    backgroundColor: controllerBGColor || 'transparent',
    border: `0.0833rem solid ${colors.borderGrey}`,

    ':hover': {
      border: `0.0833rem solid ${colors.borderGrey}`,
    },
    boxShadow: `none`,
    padding: 0,
    color: 'white',
  }),
  menu: styles => ({
    ...styles,
    border: `0.0833rem solid var(--sys)`,
    position: 'absolute',
    zIndex: 10000,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontSize: '1.3333rem',
      fontFamily: isFonts ? data.value : 'Open Sans',
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? 'var(--sys-bg)'
        : isFocused
        ? 'transpaernt'
        : undefined,
      color: isDisabled ? '#ccc' : isFocused ? 'var(--sys)' : 'black',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      border: isFocused ? `0.0833rem solid var(--sys)` : `0.0833rem solid transparent`,

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled ? 'var(--sys)' : undefined,
        color: !isDisabled ? 'white' : undefined,
      },
    };
  },
  input: styles => ({ ...styles, color: 'white' }),
  placeholder: (styles, { data }) => ({
    ...styles,
    fontFamily: isFonts && data ? data.value : 'Open Sans',
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    color: 'white',
  }),
  loadingIndicator: styles => ({ ...styles, marginRight: 30 }),
});

const Option = ({ children, ...props }) => {
  const { onMouseMove, ...rest } = props.innerProps;
  const newProps = Object.assign(props, { innerProps: rest });
  return <components.Option {...newProps}>{children}</components.Option>;
};

export const loadAsyncOptions = ({
  selectAllOption = false,
  loadedOptions,
  getItems,
  params,
  itemsKey,
  setItemsExternally,
  setSelectedItem,
}) =>
  getItems(params).then(res => {
    if (res.success) {
      const options = res[itemsKey].map(item => ({
        label: item.title || item.name,
        value: item.id,
      }));

      if (selectAllOption) options.unshift({ label: 'Select all', value: 'select-all' });

      setItemsExternally([...loadedOptions, ...options]);

      if (params.offset === 0 && params.search === '' && options.length && setSelectedItem) {
        setSelectedItem([...loadedOptions, ...options][0].value);
      }

      return {
        options,
        hasMore: params.offset + params.limit < res.count,
        additional: {
          offset: params.offset + params.limit,
        },
      };
    }
  });

function SelectWrapper({
  options,
  value,
  setValue,
  placeholder,
  isClearable = false,
  isSearchable = true,
  onFocus,
  onBlur,
  isFonts = false,
  async = false,
  loadOptions,
  hideSelectedOptions = false,
  key,
  menuPalcement,
  disabled,
  controllerBGColor,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef();
  // const asyncRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    if (selectedValue && value === selectedValue.value) return;
    setSelectedValue(options.find(o => o.value === value) || null);
  }, [value, options]);

  return (
    <div className={`select-wrapper`}>
      <span
        className={`arrow ${isOpen ? 'rotate-180' : ''}`}
        onMouseUp={e => {
          if (isOpen && selectRef.current) selectRef.current.blur();
          else if (selectRef.current) selectRef.current.focus();
        }}
      >
        <IncludeSvg name="arrow-down" />
      </span>
      {!async && (
        <Select
          ref={selectRef}
          openMenuOnFocus={true}
          className={'select'}
          isClearable={isClearable}
          options={options}
          value={selectedValue}
          onChange={option => {
            setValue(option.value);
          }}
          placeholder={placeholder}
          isSearchable={isSearchable}
          onMenuOpen={() => {
            onFocus && onFocus();
            setIsOpen(true);
          }}
          onMenuClose={() => {
            onBlur && onBlur();
            setIsOpen(false);
          }}
          hideSelectedOptions={hideSelectedOptions}
          // closeMenuOnSelect={false}
          styles={{ ...customStyles({ isFonts, controllerBGColor }) }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            Option,
          }}
          menuPlacement={menuPalcement || 'auto'}
          minMenuHeight={300}
          isDisabled={disabled}
        />
      )}

      {async && (
        <AsyncPaginate
          key={key}
          // ref={(ref) => {
          //   asyncRef = ref;
          // }}
          value={selectedValue}
          loadOptions={loadOptions}
          additional={{
            // for each search
            offset: 0,
          }}
          className={'select'}
          isClearable={isClearable}
          onChange={option => {
            setValue(option.value);
          }}
          placeholder={placeholder}
          isSearchable={isSearchable}
          onMenuOpen={() => {
            onFocus && onFocus();
            setIsOpen(true);
          }}
          onMenuClose={() => {
            onBlur && onBlur();
            setIsOpen(false);
          }}
          styles={customStyles({ isFonts, controllerBGColor })}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            Option,
          }}
          isDisabled={disabled}
        />
      )}
    </div>
  );
}

export default SelectWrapper;
