import React, { useState, useEffect } from 'react';
import Toast from 'components/common/Toast';
import Spinner from 'components/common/Spinner';
import './App.scss';

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import { Provider } from 'react-redux';

import AuthObserver from 'components/router/AuthObserver';
import store from 'store/index';

// Context Providers
import PrivateRoute from 'components/router/PrivateRoute';
import StudioContextProvider from 'state/providers/StudioContextProvider';

// VIEWS
// auth
import Login from 'views/Login';

// studio
import Welcome from 'views/studio/Welcome';
// studio/formats
import Formats from 'views/studio/formats/Formats';
import ManageFormats from 'views/studio/formats/ManageFormats';
import FormatWorkspace from 'views/studio/formats/FormatWorkspace';

// studio/tempates
import Templates from 'views/studio/templates/Templates';
import TemplateWorkspace from 'views/studio/templates/TemplateWorkspace';

// studio/manuscripts
import Manuscripts from 'views/studio/manuscripts/Manuscripts';
import ManuscriptWorkspace from 'views/studio/manuscripts/ManuscriptWorkspace';

// studio/covers
import Covers from 'views/studio/covers/Covers';
import ManageCovers from 'views/studio/covers/ManageCovers';
import CoversWorkspace from 'views/studio/covers/CoversWorkspace';

// library
import WordLists from 'views/library/WordLists';
import TriviaLists from 'views/library/TriviaLists';
import ArticleLists from 'views/library/ArticleLists';
import Stories from 'views/library/Stories';
import ManageNegativeWords from 'views/library/ManageNegativeWords';
import ManageCommonWords from 'views/library/ManageCommonWords';

// Elements
import Elements from 'views/elements/Elements';
import ManageElements from 'views/elements/ManageElements';
import ElementEditor from 'views/elements/ElementEditor';

// Variables
import Variables from 'views/variables/Variables';

// Settings
import Languages from 'views/settings/languages/Languages';
import Tags from 'views/settings/tags/Tags';
import Accounts from 'views/settings/accounts/Accounts';

// others
import Placeholder from 'views/Placeholder';
// import NotFound from 'views/NotFound';

// HOOKS
import { useGlobalContext } from 'state/hooks/global';

import axios from 'axios';
function App() {
  const { msgs, setMsgs, showSpinner } = useGlobalContext();

  const [initialLoading, setInitialLoading] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setInitialLoading(false);
    }, 5000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    // this for the first load in case we already logged in
    // this only until auth observer kicks in and overwrite the token
    const access_token = localStorage.getItem('access_token');
    if (!access_token) return;
    axios.interceptors.request.use(config => {
      config.headers.Authorization = 'Bearer ' + JSON.parse(access_token);
      return config;
    });
  }, []);

  return (
    <Provider store={store}>
      <AuthObserver>
        <StudioContextProvider>
          {!initialLoading && (
            <Router>
              <Switch>
                <Route path="/login">
                  <Login />
                </Route>

                <PrivateRoute path="/home/formats/workspace">
                  <FormatWorkspace />
                </PrivateRoute>
                <PrivateRoute path="/home/formats/manage">
                  <ManageFormats />
                </PrivateRoute>
                <PrivateRoute path="/home/formats">
                  <Formats />
                </PrivateRoute>

                <PrivateRoute path="/home/templates/workspace">
                  <TemplateWorkspace />
                </PrivateRoute>
                <PrivateRoute path="/home/templates">
                  <Templates />
                </PrivateRoute>

                <PrivateRoute path="/home/manuscripts/workspace">
                  <ManuscriptWorkspace />
                </PrivateRoute>
                <PrivateRoute path="/home/manuscripts">
                  <Manuscripts />
                </PrivateRoute>

                <PrivateRoute path="/home/covers/workspace">
                  <CoversWorkspace />
                </PrivateRoute>
                <PrivateRoute path="/home/covers/manage">
                  <ManageCovers />
                </PrivateRoute>
                <PrivateRoute path="/home/covers">
                  <Covers />
                </PrivateRoute>

                <PrivateRoute path="/library/:language/word-lists">
                  <WordLists />
                </PrivateRoute>
                <PrivateRoute path="/library/:language/trivia-lists">
                  <TriviaLists />
                </PrivateRoute>
                <PrivateRoute path="/library/:language/article-lists">
                  <ArticleLists />
                </PrivateRoute>
                <PrivateRoute path="/library/:language/story-lists">
                  <Stories />
                </PrivateRoute>
                <PrivateRoute path="/library/:language/manage-negative-words">
                  <ManageNegativeWords />
                </PrivateRoute>
                <PrivateRoute path="/library/:language/manage-common-words">
                  <ManageCommonWords />
                </PrivateRoute>
                <PrivateRoute path="/elements/:elType/manage">
                  <ManageElements />
                </PrivateRoute>
                <PrivateRoute path="/elements/:elType/:catId/new" exact={true}>
                  <ElementEditor />
                </PrivateRoute>
                <PrivateRoute path="/elements/:elType/:id">
                  <ElementEditor />
                </PrivateRoute>
                <PrivateRoute path="/elements/:elType">
                  <Elements />
                </PrivateRoute>
                <PrivateRoute path="/variables/:language/manage">
                  <Variables isManage={true} />
                </PrivateRoute>
                <PrivateRoute path="/variables/:language/swaps">
                  <Variables isSwaps={true} />
                </PrivateRoute>
                <PrivateRoute path="/variables/:language/">
                  <Variables />
                </PrivateRoute>
                <PrivateRoute path="/settings/tags/:kind">
                  <Tags />
                </PrivateRoute>
                <PrivateRoute path="/settings/accounts">
                  <Accounts />
                </PrivateRoute>
                <PrivateRoute path="/settings/languages">
                  <Languages />
                </PrivateRoute>
                <PrivateRoute path="/home">
                  <Welcome />
                </PrivateRoute>
                <Route path="/" exact={true}>
                  <Redirect to="/home/formats" />
                </Route>
                <PrivateRoute path="*">
                  <Placeholder />
                </PrivateRoute>
              </Switch>
            </Router>
          )}
          <Toast msgs={msgs} setMsgs={setMsgs} />
          {showSpinner && <Spinner />}
        </StudioContextProvider>
      </AuthObserver>
    </Provider>
  );
}

export default App;
