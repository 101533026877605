import SelectWrapper from 'components/forms/elements/SelectWrapper';

import Button from 'components/common/Button';
import InputNumber from 'components/forms/elements/InputNumber';
import { capitalizeFirst } from 'helpers/text';
import _ from 'lodash';

function CodeWordForm({
  codeWordFormInputs,
  codeWordFormData,
  setCodeWordFormData,
  updateManuscriptWithCodeWord,
}) {
  return (
    <div className="code-word-form">
      {Object.keys(codeWordFormInputs.groups).map((groupTitle, index) => (
        <>
          <div className={`form-group`}>
            <label>{capitalizeFirst(groupTitle.replace('_', ' '), true)}</label>
            <div className={`row ${groupTitle !== 'language' ? 'row-cols-2' : ''}`}>
              {codeWordFormInputs.groups[groupTitle].map(input =>
                input.type === 'number' ? (
                  <div className="col mb-2">
                    <label className="mb-2">{input.label}</label>
                    <InputNumber
                      value={codeWordFormData[input.dataField]}
                      setValue={value =>
                        setCodeWordFormData({
                          ...codeWordFormData,
                          [input.dataField]: value,
                        })
                      }
                      className="form-control"
                      type="number"
                      step={1}
                      min={1}
                    />
                  </div>
                ) : (
                  <div className="col mb-2">
                    <label className="mb-2">{input.label}</label>

                    <SelectWrapper
                      options={input.options}
                      value={codeWordFormData[input.dataField]}
                      setValue={value => {
                        const newFormData = {
                          ...codeWordFormData,
                          [input.dataField]: value,
                        };
                        if (input.dataField === 'nrows') newFormData['ncols'] = value;
                        setCodeWordFormData(newFormData);
                      }}
                      menuPalcement={['Puzzle Shape', 'Difficulty'].includes(input.label) && 'top'}
                    />
                  </div>
                )
              )}

              <div className="d-flex w-100 justify-content-center mt-4">
                <Button
                  onClick={() => updateManuscriptWithCodeWord()}
                  label={'Load Puzzles'}
                  className="btn-blue-forms"
                  style={{ width: 150, height: 40 }}
                />
              </div>
            </div>
          </div>
        </>
      ))}
    </div>
  );
}

export default CodeWordForm;
