import React from 'react';
import { Text, Group, Rect, Line } from 'react-konva';
import LinkIndicator from '../LinkIndicator';
import { sliceIntoChunks, extractCellsContent } from 'settings/puzzels';
import { mergeArrays } from 'settings/puzzels';
const alphas = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const TGridStatic = ({
  type,
  shapeProps,
  isEditMode,
  setIsEditMode,
  isSelected,
  setSelectedShapeId,
  onSelect,
  onDragStart,
  onDragMove,
  onDragEnd,
  onChange,
  trForceUpdate,
}) => {
  const shapeRef = React.useRef();
  const [grid, setGrid] = React.useState([]);

  const isAlpha = type.includes('alphabets');
  const isMap = type.includes('map');
  const isVertical = type.includes('vt');
  const isHorizontal = type.includes('hz');
  const doneLetters = extractCellsContent(shapeProps.text, '').filter(cell => cell !== ' ');

  const nRows = shapeProps.staticLink.rows;
  const nCols = shapeProps.staticLink.cols;

  const mapNumColor = shapeProps.staticLink.borders.checked4
    ? shapeProps.staticLink.borders.color4
    : 'rgba(0,0,0,0)';

  const mapNumsFontSize = shapeProps.staticLink.borders.checked4
    ? shapeProps.staticLink.borders.width4
    : 0;

  const mapNumsBgColor = shapeProps.staticLink.borders.checked3
    ? shapeProps.staticLink.borders.color3
    : 'rgba(0,0,0,0)';

  const mapAlphaBgColor = shapeProps.staticLink.borders.checked5
    ? shapeProps.staticLink.borders.color5
    : 'rgba(0,0,0,0)';

  React.useEffect(() => {
    let cells;
    if (isAlpha) {
      cells = extractCellsContent(shapeProps.text, '');
    } else if (isMap) {
      const text = extractCellsContent(shapeProps.text, '');
      const mapSecText = extractCellsContent(shapeProps.staticLink.mapSecText, ',');
      const direction = isVertical ? 'vt' : 'hz';
      cells = mergeArrays(mapSecText, text, nCols, direction);
    }
    const rows = sliceIntoChunks(cells, nCols);
    let grid;
    if (isAlpha) {
      grid = [
        alphas.split('').map((cell, index) => {
          return {
            text: cell,
            x: isHorizontal ? index * (shapeProps.width / nCols) : 0,
            y: isHorizontal ? 0 : index * (shapeProps.height / nRows),
            isMap: false,
            isNum: false,
            isAlpha: true,
            isADoneLetter: doneLetters.includes(cell),
          };
        }),
      ];
    } else if (isMap) {
      grid = rows.map((row, rowIndex) => {
        return row.map((cell, colIndex) => {
          return {
            text:
              isAlpha && isHorizontal
                ? alphas[colIndex]
                : isAlpha && isVertical
                ? alphas[rowIndex]
                : cell,
            x: colIndex * (shapeProps.width / nCols),
            y: rowIndex * (shapeProps.height / nRows),
            // if map is vertical odd columns will have background
            // if map is horizontal odd rows will have background
            isMap: true,
            isNum:
              isMap && isVertical
                ? (colIndex + 1) % 2 === 1
                : isMap && isHorizontal
                ? (rowIndex + 1) % 2 === 1
                : false,
            isAlpha:
              isMap && isVertical
                ? (colIndex + 1) % 2 !== 1
                : isMap && isHorizontal
                ? (rowIndex + 1) % 2 !== 1
                : false,
            isADoneLetter: false,
          };
        });
      });
    }

    setGrid(grid);
  }, [
    shapeProps.height,
    shapeProps.width,
    shapeProps.staticLink.rows,
    shapeProps.staticLink.cols,
    shapeProps.text,
    isAlpha,
    isMap,
    isHorizontal,
    isVertical,
  ]);

  return (
    <>
      {shapeProps.link && isSelected && !isEditMode && (
        <LinkIndicator shapeProps={shapeProps} offset={false} />
      )}
      <Group
        onMouseDown={onSelect}
        onTap={onSelect}
        onDblTap={() => {
          setSelectedShapeId(null); // UI fix - Don't remove it
          setIsEditMode(true);
          setSelectedShapeId(shapeProps.id);
        }}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragStart={onDragStart}
        onDragMove={onDragMove}
        onDragEnd={e => onDragEnd(shapeProps, e)}
        onTransformEnd={e => {
          // transformer is changing scale of the node
          // and NOT its width or height- shapeRef.current.fontSize()/scale/100/2 - shapeRef.current.padding()/scale/100
          // but in the store we have only width and height
          // to match the data better we will re- shapeRef.current.fontSize()/scale/100/2 - shapeRef.current.padding()/scale/100set scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);

          onChange(
            {
              ...shapeProps,
              x: node.x(),
              y: node.y(), // set minimal value
              width: node.width() * scaleX,
              // height: node.width() * scaleX, // Quick fix
              height: node.height() * scaleY,
              scaleX: 1,
              scaleY: 1,
              rotation: node.rotation(),
            },
            true
          );
          trForceUpdate();
        }}
      >
        {grid.map((cellsChunk, i) =>
          cellsChunk.map((word, j) => {
            return (
              <>
                {i < nRows && word.isMap && (
                  <Rect
                    key={'rect' + 'col' + j + '-' + 'row' + i}
                    x={word.x}
                    y={word.y}
                    width={shapeProps.width / nCols}
                    height={shapeProps.height / nRows}
                    fill={word.isNum ? mapNumsBgColor : mapAlphaBgColor}
                  />
                )}
                {i < nRows && j < nCols && (
                  <Text
                    ellipsis
                    wrap={'none'}
                    key={'text' + 'col' + j + '-' + 'row' + i}
                    {...shapeProps}
                    x={word.x}
                    y={word.y}
                    fontSize={word.isMap && word.isNum ? mapNumsFontSize : shapeProps.fontSize}
                    width={shapeProps.width / nCols}
                    height={shapeProps.height / nRows}
                    lineHeight={shapeProps.lineHeight}
                    text={word.text}
                    rotation={0}
                    verticalAlign={'middle'}
                    fill={word.isMap && word.isNum ? mapNumColor : shapeProps.fill}
                  />
                )}
                {i < nRows && j < nCols && word.isADoneLetter && (
                  <Line
                    key={'line' + 'col' + j + '-' + 'row' + i}
                    points={[
                      word.x,
                      word.y,
                      word.x + shapeProps.width / nCols,
                      word.y + shapeProps.height / nRows,
                    ]}
                    stroke={
                      shapeProps.staticLink.borders.checked3
                        ? shapeProps.staticLink.borders.color3
                        : 'rgba(0,0,0,0)'
                    }
                    strokeWidth={shapeProps.staticLink.borders.width3}
                  />
                )}
              </>
            );
          })
        )}

        {[...Array(nRows).keys()].map(
          (row, i) =>
            i > 0 && (
              <Line
                points={[
                  0,
                  (shapeProps.height / nRows) * i,
                  shapeProps.width,
                  (shapeProps.height / nRows) * i,
                ]}
                stroke={
                  i === 2 && isHorizontal
                    ? shapeProps.staticLink.borders.color1
                    : shapeProps.staticLink.borders.color2
                }
                strokeWidth={
                  shapeProps.staticLink.borders.checked2
                    ? i === 2 && isHorizontal
                      ? shapeProps.staticLink.borders.width1
                      : shapeProps.staticLink.borders.width2
                    : 0
                }
              />
            )
        )}
        {[...Array(nCols).keys()].map(
          (col, j) =>
            j > 0 && (
              <Line
                points={[
                  (shapeProps.width / nCols) * j,
                  0,
                  (shapeProps.width / nCols) * j,
                  shapeProps.height,
                ]}
                stroke={
                  j === 2 && isVertical
                    ? shapeProps.staticLink.borders.color1
                    : shapeProps.staticLink.borders.color2
                }
                strokeWidth={
                  shapeProps.staticLink.borders.checked2
                    ? j === 2 && isVertical
                      ? shapeProps.staticLink.borders.width1
                      : shapeProps.staticLink.borders.width2
                    : 0
                }
              />
            )
        )}

        <Rect // this to constrain width
          key={'outline-' + shapeProps.staticLink.puzzleShape + shapeProps.id}
          x={0}
          y={0}
          width={shapeProps.width}
          height={shapeProps.height}
          stroke={
            shapeProps.staticLink.borders.checked1
              ? shapeProps.staticLink.borders.color1
              : 'rgba(0,0,0,0)'
          }
          // stroke={'rgba(0,0,0,0)'}
          fill={'rgba(0,0,0,0)'}
          strokeWidth={
            shapeProps.staticLink.borders.checked1 ? shapeProps.staticLink.borders.width1 : 0
          }
        />
      </Group>
    </>
  );
};

export default TGridStatic;
