export const PUZZLE_CONTENT_TYPES = [
  'word-search-grid - puzzle',
  'word-search-grid - solution',
  'number-search-grid - puzzle',
  'number-search-grid - solution',
  'cross-words-grid - puzzle',
  'cross-words-grid - solution',
  'code-word-grid - puzzle',
  'code-word-grid - solution',
  'sudoku-grid - puzzle',
  'sudoku-grid - solution',
  'Problem - Trivia',
  'Solution - Trivia',
  'Coloring Book Image',
  'story-word-list-text',
];

export const SUDOKU_GRIDS = [
  {
    size: [4, 4],
    blocks: [2, 2],
  },
  {
    size: [6, 6],
    blocks: [3, 2], // [cols , rows]
  },
  {
    size: [8, 8],
    blocks: [4, 2],
  },
  {
    size: [9, 9],
    blocks: [3, 3],
  },
  {
    size: [12, 12],
    blocks: [4, 3],
  },
  {
    size: [14, 14],
    blocks: [7, 2],
  },
  {
    size: [16, 16],
    blocks: [4, 4],
  },
  {
    size: [25, 25],
    blocks: [5, 5],
  },
];

// diffculty options
export const DIFFICULTY_OPTS = [
  {
    label: 'Easy',
    value: 'easy',
  },
  {
    label: 'Medium',
    value: 'medium',
  },
  {
    label: 'Hard',
    value: 'hard',
  },
  {
    label: 'Very Hard',
    value: 'very_hard',
  },
];

export const PUZZLE_SHAPE_OPTS = [
  {
    label: 'Normal',
    value: 'normal',
  },
  {
    label: 'Circle',
    value: 'circle',
  },
  {
    label: 'Squares',
    value: 'squares',
  },
  {
    label: 'Diamond',
    value: 'diamond',
  },
  {
    label: 'Triangle',
    value: 'triangle',
  },
  {
    label: 'Star',
    value: 'star',
  },
  {
    label: 'BowTie',
    value: 'BowTie',
  },
];

export const extractCellsContent = (text, sep = '') => text.split(sep);

export const mergeArrays = (secText, text, cols, direction) => {
  if (cols <= 0) {
    throw new Error('Invalid cols value');
  }

  const result = [];

  if (direction === 'hz') {
    // Horizontal merge logic
    let secTextIndex = 0;
    let textIndex = 0;

    while (secTextIndex < secText.length || textIndex < text.length) {
      // Add `cols` elements from secText if available
      for (let i = 0; i < cols && secTextIndex < secText.length; i++) {
        result.push(secText[secTextIndex++]);
      }

      // Add `cols` elements from text if available
      for (let i = 0; i < cols && textIndex < text.length; i++) {
        result.push(text[textIndex++]);
      }
    }
  } else if (direction === 'vt') {
    // cols will be always 4 -> a brute force solution would be ...
    // would need optimazation if this function used in other places
    for (let i = 0; i < text.length / 2; i++) {
      result.push(secText[i]);
      result.push(text[i]);
      result.push(secText[i + text.length / 2]);
      result.push(text[i + text.length / 2]);
    }
  } else {
    throw new Error('Invalid direction value');
  }

  return result;
};

export const sliceIntoChunks = (arr, chunkSize) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

export const WORDSEARCH_DESIGN = {
  normal: {
    min: 11,
    odd: false,
    xEqY: false,
  },
  circle: {
    min: 11,
    odd: true,
    xEqY: true,
  },
  squares: {
    min: 11,
    odd: true,
    xEqY: true,
    points: shapeProps => {
      const rows = shapeProps.staticLink.rows;
      const cols = shapeProps.staticLink.cols;
      // split string in 2d array
      const t = typeof shapeProps.text === 'object' ? shapeProps.text.grid_str : shapeProps.text;
      const cells = extractCellsContent(t);
      const grid = sliceIntoChunks(cells, cols);
      const points = [];

      let long = 0;
      let short = 0;
      if (grid[0].length)
        // long will be the num of empty cells in long direction
        long = grid[0].reduce((acc, cell) => {
          if (cell === ' ') return acc + 1;
          return acc;
        }, 0);

      if (grid[Math.round(rows / 2)] && grid[Math.round(rows / 2)].length)
        // short will be the num of empty cells in short direction
        short = grid[Math.round(rows / 2)].reduce((acc, cell) => {
          if (cell === ' ') return acc + 1;
          return acc;
        }, 0);

      const w1 = (shapeProps.width - long * (shapeProps.width / cols)) / 2;
      const h1 = (short / 2) * (shapeProps.height / rows);
      const w2 = long * (shapeProps.width / cols);

      // Top line
      // point A
      points.push(0, 0);

      // points B, C, D, E
      points.push(w1, 0);
      points.push(w1, h1);
      points.push(w1 + w2, h1);
      points.push(w1 + w2, 0);

      // Point F
      points.push(shapeProps.width, 0);

      // Point G, H, I, J
      points.push(shapeProps.width, w1);
      points.push(shapeProps.width - h1, w1);
      points.push(shapeProps.width - h1, w1 + w2);
      points.push(shapeProps.width, w1 + w2);

      // Point K
      points.push(shapeProps.width, shapeProps.height);

      // Point L, M, N, O
      points.push(shapeProps.width - w1, shapeProps.height);
      points.push(shapeProps.width - w1, shapeProps.height - h1);
      points.push(shapeProps.width - w1 - w2, shapeProps.height - h1);
      points.push(shapeProps.width - w1 - w2, shapeProps.height);

      // Point P
      points.push(0, shapeProps.height);

      // Point Q, R, S, T
      points.push(0, shapeProps.height - w1);
      points.push(h1, shapeProps.height - w1);
      points.push(h1, shapeProps.height - w1 - w2);
      points.push(0, shapeProps.height - w1 - w2);

      // Point A
      points.push(0, 0);
      return points;
    },
  },
  diamond: {
    min: 51,
    odd: true,
    xEqY: true,
    points: () => [],
  },
  triangle: {
    min: 51,
    odd: true,
    xEqY: true,
    points: () => [],
  },
  star: {
    min: 21,
    odd: true,
    xEqY: true,
    points: () => [],
  },
  bowtie: {
    min: 11,
    odd: true,
    xEqY: true,
    points: shapeProps => {
      const points = [];

      points.push(0, 0);
      points.push(shapeProps.width / 2, shapeProps.height / 2);
      points.push(shapeProps.width, 0);
      points.push(shapeProps.width, shapeProps.height);
      points.push(shapeProps.width / 2, shapeProps.height / 2);
      points.push(0, shapeProps.height);
      points.push(0, 0);

      return points;
    },
  },
};

export const CROSSWORDS_DESIGN = {
  normal: {
    min: 13,
    odd: false,
    xEqY: false,
    sizes: [13, 15],
  },
};

export const CODEWORD_DESIGN = {
  normal: {
    min: 13,
    odd: false,
    xEqY: false,
    sizes: [13, 15],
  },
};
