import Story from './Story';
import 'components/common/ListEditor.scss';
import PopupMenu from 'components/common/PopupMenu';
import Tags from 'components/common/Tags';
import { capitalizeFirst } from 'helpers/text';

import React, { useState, useEffect, useRef } from 'react';
import { addStory, editStory, getOneStory, getStories } from 'api/library';
import { useGlobalContext } from 'state/hooks/global';
import IncludeSvg from 'components/common/IncludeSvg';
import { useOnClickOutside } from 'hooks/util';
import { useSelector } from 'react-redux';
import { default as SlideModal } from 'react-slide-out';
import { useOnClickOnEscape } from 'hooks/util';

import _ from 'lodash';

const OPTS_A = [];
const OPTS_B = () =>
  _.cloneDeep([
    {
      title: '',
      content: '',
    },
  ]);

function StoryEditor({
  initIsNew,
  offset,
  setOffset,
  allowedTags,
  tagsToFilter,
  sortBy,
  setSortBy,
  setShowStoryEditor,
  setNewItemFromSlideModal,
}) {
  const { user } = useSelector(state => state.auth);
  const canChangeStorys = user?.resources_permissions?.story?.change_story;
  const [listName, setListName] = useState('');
  const [count, setCount] = useState(0);
  const [listA, setListA] = useState(OPTS_A);
  const [listB, setListB] = useState(OPTS_B());
  const [error, setError] = useState(false);
  const [id, setId] = useState(null);
  const [isNew, setIsNew] = useState(initIsNew);
  const [tagsArr, setTagsArr] = useState([]);
  const [popup, setPopup] = useState(false);
  const popupRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSavable, setIsSavable] = useState(true);
  const [showSlideModal, setShowSlideModal] = useState(true);

  useOnClickOutside(popupRef, () => setPopup(false));

  const [selectedStoriesListA, setselectedStoriesListA] = useState([]);
  const [selectedStoriesListB, setselectedStoriesListB] = useState([]);
  const [transferToATrig, setTransferToATrig] = useState({
    val: false,
    all: false,
    difficulty: null,
  });
  const [transferToBTrig, setTransferToBTrig] = useState({
    val: false,
    all: false,
  });
  const [letterCase, setLetterCase] = useState('AA');
  const listNameRef = useRef(null);

  const { setMsgs } = useGlobalContext();

  const transferToA = list => {
    setListA(_.cloneDeep([...listA, ...list]));
  };

  const transferToB = list => {
    setListB(_.cloneDeep([...listB, ...list]));
  };

  const transfer = (dir, all, difficulty) => {
    switch (dir) {
      case 'toA':
        setTransferToATrig({ val: true, all, difficulty });
        break;
      case 'toB':
        setTransferToBTrig({ val: true, all });
        break;
    }
  };

  const popupOptions = [
    {
      title: 'Alphabetically',
      value: 'alpha',
      handler: () => {
        setSortBy('alpha');
        setPopup(false);
      },
    },
    {
      title: 'Recently Added',
      value: 'recently_added',
      handler: () => {
        setSortBy('recently_added');
        setPopup(false);
      },
    },
    {
      title: 'Most Popular',
      value: 'most_popular',
      handler: () => {
        setSortBy('most_popular');
        setPopup(false);
      },
    },
  ];

  const handleNext = () => {
    if (offset.value === null) {
      setOffset({ category_id: offset.category_id, value: 0 });
      setIsNew(false);
    } else if (offset.value < count - 1)
      setOffset({ category_id: offset.category_id, value: offset.value + 1 });
  };

  const handlePrev = () => {
    if (offset.value === 0) {
      setIsNew(true);
      setOffset({ category_id: offset.category_id, value: null });
    } else if (offset.value > 0)
      setOffset({ category_id: offset.category_id, value: offset.value - 1 });
  };

  const handleSave = () => {
    if (!listName.trim()) {
      setError(true);
      setListName('');
      listNameRef.current.focus();
      setMsgs([{ type: 'error', content: 'List name is missing!' }]);
      return;
    }

    const data = {
      title: listName.trim(),
      tags: tagsArr.map(tag => tag.id),
      created_by: 2, // TEMP Fix Later Backend should Handle
      items: listA,
      draft_items: listB,
      is_published: true,
    };

    if (isNew) {
      data['category'] = offset.category_id;
      addStory(data).then(res => {
        if (res.success) {
          setIsNew(false);
          setId(res.story.id);
          setMsgs([{ type: 'success', content: 'Story List saved successfully!' }]);
          setNewItemFromSlideModal(res.story);
        }
      });
    } else {
      editStory(data, id).then(res => {
        if (res.success) setMsgs([{ type: 'success', content: 'Story list is updated!' }]);
      });
    }
    setIsSavable(false);
  };

  const updateList = (list, setter, cb, editable) => {
    const l = list.slice();
    const updatedList = cb(l);

    if (editable && updatedList.length === 0) setter([...updatedList, ...OPTS_B()]);
    else setter(updatedList);
    setselectedStoriesListA([]);
    setselectedStoriesListB([]);
  };

  const sortList = (list, setList, dir) => {
    switch (dir) {
      case 'Ascending':
        updateList(list, setList, l =>
          l.sort((a, b) => {
            if (a.title < b.title) return -1;
            if (b.title < a.title) return 1;
            return 0;
          })
        );
        break;
      case 'Descending':
        updateList(list, setList, l =>
          l.sort((a, b) => {
            if (a.title > b.title) return -1;
            if (b.title > a.title) return 1;
            return 0;
          })
        );
        break;
    }
  };

  const formatStory = (format, story) => {
    let val;
    let t = { title: story.title, content: story.content };
    switch (format) {
      case 'AA':
        val = {
          ...t,
          title: t.title.toUpperCase(),
          content: t.content.toUpperCase(),
        };
        break;

      case 'Aa':
        val = {
          ...t,
          title: capitalizeFirst(t.title.toLowerCase()),
          content: capitalizeFirst(t.content.toLowerCase()),
        };

        break;
      case 'aa':
      default:
        val = {
          ...t,
          title: t.title.toLowerCase(),
          content: t.content.toLowerCase(),
        };
        break;
    }
    return val;
  };

  useEffect(() => {
    setIsSavable(true);
  }, [listA, listB, tagsArr]);

  useEffect(() => {
    setIsNew(initIsNew);
  }, [initIsNew]);

  // useEffect(() => {
  //   updateList(setListB, list => list.map(word => formatStory(letterCase, word)));
  // }, [letterCase]);

  useEffect(() => {
    setIsLoading(true);
    setListName('');
    setTagsArr([]);
    setId(null);
    setListA([]);
    setListB(OPTS_B());
    getStories({
      category_id: offset.category_id,
      sort: sortBy,
      tags: tagsToFilter,
      offset: offset.value,
      limit: 1, //limit is 1
    }).then(res => {
      if (res.success && !isNew) {
        // alert(JSON.stringify(res, 0, 4));
        setListName(res.stories[0].title);
        setId(res.stories[0].id);
        setCount(res.count);

        return getOneStory(res.stories[0].id).then(res => {
          if (res.success) {
            setListA(
              typeof res.story.items === 'object' && res.story.items !== null
                ? res.story.items
                : // .map(item => formatStory('AA', item))
                  OPTS_A
            );
            setListB(
              typeof res.story.draft_items === 'object' && res.story.draft_items !== null
                ? res.story.draft_items
                : // .map(item => formatStory('AA', item))
                  OPTS_B()
            );
            setTagsArr(
              res.story.tags
                .map(id => allowedTags.find(tag => tag.id === id))
                .filter(tag => tag !== undefined) // in case category deleted
            );
            // sortList('Ascending');
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        });
      } else {
        setCount(res.count);
        setIsLoading(false);
      }
    });
  }, [offset.value, sortBy]);

  const titleOf = objVal => {
    return popupOptions.find(option => option.value === objVal).title;
  };

  useEffect(() => {
    if (isNew) {
      setShowSlideModal(true);
    }
  }, [isNew]);
  const closeSlideModal = () => {
    setShowSlideModal(false);
    setShowStoryEditor(false);
  };

  useOnClickOnEscape(closeSlideModal);

  const bulkUploadInputRef = useRef();

  const showFileSelector = () => {
    bulkUploadInputRef.current.click();
  };

  const handleBulkUpload = e => {
    const fileInput = e.target;
    const file = fileInput.files[0];

    if (file) {
      const fileNameWithoutExtension = file.name.split('.')[0];
      setListName(fileNameWithoutExtension);

      const reader = new FileReader();
      reader.onload = function (e) {
        const text = e.target.result;

        // Regular expression to match the sections
        const regex = /title:\s*(.*?)\s*content:\s*(.*?)\s*(?=title:|$)/gs;

        const stories = [];
        let match;

        // Using the regex to find all matches in the text
        while ((match = regex.exec(text)) !== null) {
          const [_, title, content] = match;
          stories.push({
            title: title.trim(),
            content: content.trim(),
            difficulty: '-', // Assuming this field is static as per your example
          });
        }

        setListB(stories);
      };

      reader.readAsText(file);
    }
  };

  return (
    <SlideModal isOpen={showSlideModal} onOutsideClick={closeSlideModal}>
      <div className="pt-5 bg-white" style={{ height: '100%' }}>
        <button className="mb-5 form__spec--close-btn" onClick={closeSlideModal}>
          x
        </button>
        <div className="list-editor">
          <div className="list-editor__body">
            <div className="d-flex flex-column w-100 my-4">
              <div className="list-editor__controls ">
                <div className="list-editor__controls--name shadow-sm">
                  <input
                    ref={listNameRef}
                    value={listName}
                    onChange={e => setListName(e.target.value)}
                    className={error ? 'error' : ''}
                    placeholder="List name here..."
                  />
                  <div
                    className="group noselect sort-by"
                    onClick={() => {
                      setPopup(!popup);
                    }}
                  >
                    <span className="variable">{titleOf(sortBy)}</span>
                    <span className={`${popup ? 'rotate-180' : ''}`}>
                      <IncludeSvg name="arrow-drop-down" />
                    </span>
                    {popup && <PopupMenu popupRef={popupRef} options={popupOptions} />}
                  </div>
                </div>

                <div className={`right d-flex align-items-center justify-content-end`}>
                  <span className="details truncate">
                    <span className="text-bold">
                      {offset.value === null ? 0 : offset.value + 1} of {count}
                    </span>{' '}
                    Story list
                  </span>
                  <button
                    disabled={isLoading && offset.value === 0}
                    className={`arrow ${offset.value != null && !isLoading ? 'active' : ''}`}
                    onClick={handlePrev}
                  >
                    <IncludeSvg name={'arrow-left'} />
                  </button>
                  <button
                    className={`arrow  ${offset.value < count - 1 ? 'active' : ''}`}
                    onClick={handleNext}
                  >
                    <IncludeSvg name={'arrow-right'} />
                  </button>
                </div>
              </div>

              <Story
                key="listA"
                listName="listA"
                title={''}
                stories={listA}
                setStories={setListA}
                selectedStories={selectedStoriesListA}
                setSelectedStories={setselectedStoriesListA}
                size={32}
                editable={false}
                transfer={transferToB}
                transferTrig={transferToBTrig}
                setTransferTrig={setTransferToBTrig}
                offset={offset}
                isLoading={isLoading}
                formatStory={formatStory}
                updateList={updateList}
                sortList={sortList}
                letterCase={letterCase}
                setLetterCase={setLetterCase}
              />
            </div>
            <div className="list-editor__body--contorls story">
              <div>
                <div>
                  <button
                    disabled={!selectedStoriesListB.length}
                    onClick={() => transfer('toA', false, 'easy')}
                    className="story blue shadow-sm"
                  >
                    &lsaquo;
                  </button>{' '}
                  <span>Easy</span>
                </div>
                <div>
                  <button
                    disabled={!selectedStoriesListB.length}
                    onClick={() => transfer('toA', false, 'medium')}
                    className="story blue shadow-sm"
                  >
                    &lsaquo;
                  </button>{' '}
                  <span>Medium</span>
                </div>

                <div>
                  <button
                    disabled={!selectedStoriesListB.length}
                    onClick={() => transfer('toA', false, 'hard')}
                    className="story blue shadow-sm"
                  >
                    &lsaquo;
                  </button>{' '}
                  <span>Hard</span>
                </div>

                <button
                  disabled={!selectedStoriesListA.length}
                  onClick={() => transfer('toB', false)}
                  className="story white shadow-sm"
                >
                  &rsaquo;
                </button>
              </div>
            </div>
            <div className="d-flex flex-column w-100 dark p-4">
              <div className="borderd-sec p-4">
                <div className="list-title">STORY LISTS</div>
                <input
                  ref={bulkUploadInputRef}
                  type="file"
                  className="d-none"
                  onChange={handleBulkUpload}
                />
                <div className="d-flex">
                  <button onClick={showFileSelector} className="bulk-upload-btn">
                    BULK UPLOAD
                  </button>
                </div>
                <Story
                  editable
                  firstItemAlwaysSeleted
                  key="listB"
                  listName="listB"
                  title={''}
                  stories={listB}
                  setStories={setListB}
                  selectedStories={selectedStoriesListB}
                  setSelectedStories={setselectedStoriesListB}
                  size={16}
                  transfer={transferToA}
                  transferTrig={transferToATrig}
                  setTransferTrig={setTransferToATrig}
                  offset={offset}
                  isLoading={isLoading}
                  formatStory={formatStory}
                  updateList={updateList}
                  sortList={sortList}
                  letterCase={letterCase}
                  setLetterCase={setLetterCase}
                  showListControls={false}
                />
              </div>
              <div
                className="p-4 borderd-sec shadow mt-4 form__spec--controls"
                style={{ marginRight: 0 }}
              >
                <Tags
                  title={'Tags'}
                  allowedTags={allowedTags}
                  tagsArr={tagsArr}
                  setTagsArr={setTagsArr}
                  lassNameA={'form-group'}
                  classNameB={''}
                  inputClassName={'form-control light'}
                  height={'4.1667rem'}
                  inputPlaceholder={'Teens, Adults, Seasons,... etc'}
                />
              </div>
              <button
                style={{ visibility: canChangeStorys ? 'visible' : 'hidden' }}
                disabled={!isSavable}
                onClick={handleSave}
                className="purple w-25 align-self-center"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </SlideModal>
  );
}

export default StoryEditor;
