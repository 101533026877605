export default function useManuscriptFormInputs({ generalFormInputFilterArr, languages }) {
  const generalFormInputs = [
    { label: 'Heading', dataField: 'heading' },
    { label: 'Subheading', dataField: 'subheading' },
    { label: 'ISBN', dataField: 'isbn' },
    { label: 'Author(s)', dataField: 'authors' },
    { label: 'Text 1', dataField: 'text_1' },
    { label: 'Text 2', dataField: 'text_2' },
    { label: 'Text 3', dataField: 'text_3' },
    { label: 'Text 4', dataField: 'text_4' },
    { label: 'Text 5', dataField: 'text_5' },
    { label: 'Text 6', dataField: 'text_6' },
  ].filter(input => generalFormInputFilterArr.includes(input.label));

  const triviaFormInputs = {
    groups: {
      language: [
        {
          type: 'select',
          label: 'Language',
          dataField: 'language_id',
          options: languages.map(lang => ({
            label: lang.name,
            value: lang.id,
          })),
        },
      ],
      words_range: [
        { type: 'number', label: 'Min. (Q)', dataField: 'q_min_words' },
        { type: 'number', label: 'Max. (Q)', dataField: 'q_max_words' },
        { type: 'number', label: 'Min. (A)', dataField: 'a_min_words' },
        { type: 'number', label: 'Max. (A)', dataField: 'a_max_words' },
      ],
      letters_range: [
        { type: 'number', label: 'Min. (Q)', dataField: 'q_min_letters' },
        { type: 'number', label: 'Max. (Q)', dataField: 'q_max_letters' },
        { type: 'number', label: 'Min. (A)', dataField: 'a_min_letters' },
        { type: 'number', label: 'Max. (A)', dataField: 'a_max_letters' },
      ],
    },
  };

  const wordSearchFormInputs = {
    groups: {
      language: [
        {
          type: 'select',
          label: '',
          dataField: 'language_id',
          options: languages.map(lang => ({
            label: lang.name,
            value: lang.id,
          })),
        },
      ],
      generated_puzzles: [
        { type: 'number', label: 'Cols', dataField: 'ncols' },
        { type: 'number', label: 'Rows', dataField: 'nrows' },
        { type: 'number', label: 'Num. of puzzles', dataField: 'npuzzles' },
        { type: 'number', label: 'Num. of words', dataField: 'nwords' },
        {
          type: 'select',
          label: 'Puzzle Shape',
          dataField: 'puzzle_shape',
          options: [
            {
              label: 'Normal',
              value: 'normal',
            },
            {
              label: 'Circle',
              value: 'circle',
            },
            {
              label: 'Squares',
              value: 'squares',
            },
            {
              label: 'Diamond',
              value: 'diamond',
            },
            {
              label: 'Triangle',
              value: 'triangle',
            },
            {
              label: 'Star',
              value: 'star',
            },
            {
              label: 'BowTie',
              value: 'BowTie',
            },
          ],
        },
        {
          type: 'select',
          label: 'Difficulty',
          dataField: 'difficulty',
          isSecondaryField: true,
          options: [
            {
              label: 'Easy',
              value: 'easy',
            },
            {
              label: 'Medium',
              value: 'medium',
            },
            {
              label: 'Hard',
              value: 'hard',
            },
            {
              label: 'Very Hard',
              value: 'very_hard',
            },
          ],
        },
        { type: 'number', label: 'Min Letters', dataField: 'min_chars', isSecondaryField: true },
        { type: 'number', label: 'Max Letters', dataField: 'max_chars', isSecondaryField: true },
        {
          type: 'select',
          label: 'For',
          dataField: 'filter_words',
          options: [
            {
              label: 'Kids',
              value: true,
            },
            {
              label: 'Adults',
              value: false,
            },
          ],
        },
        { type: 'date', label: 'Starting Date (for Dynamic Dates)', dataField: 'starting_date' },
      ],
    },
  };

  const numberSearchFormInputs = {
    groups: {
      generated_puzzles: [
        { type: 'number', label: 'Cols', dataField: 'ncols' },
        { type: 'number', label: 'Rows', dataField: 'nrows' },
        { type: 'number', label: 'Num. of puzzles', dataField: 'npuzzles' },
        { type: 'number', label: 'Num. of Nums', dataField: 'nwords' },
        {
          type: 'select',
          label: 'Puzzle Shape',
          dataField: 'puzzle_shape',
          options: [
            {
              label: 'Normal',
              value: 'normal',
            },
            {
              label: 'Circle',
              value: 'circle',
            },
            {
              label: 'Squares',
              value: 'squares',
            },
            {
              label: 'Diamond',
              value: 'diamond',
            },
            {
              label: 'Triangle',
              value: 'triangle',
            },
            {
              label: 'Star',
              value: 'star',
            },
            {
              label: 'BowTie',
              value: 'BowTie',
            },
          ],
        },
        {
          type: 'select',
          label: 'Difficulty',
          dataField: 'difficulty',
          options: [
            {
              label: 'Easy',
              value: 'easy',
            },
            {
              label: 'Medium',
              value: 'medium',
            },
            {
              label: 'Hard',
              value: 'hard',
            },
            {
              label: 'Very Hard',
              value: 'very_hard',
            },
          ],
        },
        { type: 'number', label: 'Min Digits', dataField: 'min_chars' },
        { type: 'number', label: 'Max Digits', dataField: 'max_chars' },
      ],
    },
  };

  const sudokuFormInputs = {
    groups: {
      generated_puzzles: [
        {
          type: 'select',
          label: 'Size',
          dataField: 'size',
          options: [
            {
              label: '4 X 4',
              value: 4,
            },
            {
              label: '6 X 6',
              value: 6,
            },
            {
              label: '8 X 8',
              value: 8,
            },
            {
              label: '9 X 9',
              value: 9,
            },
            {
              label: '12 X 12',
              value: 12,
            },
            {
              label: '14 X 14',
              value: 14,
            },
            {
              label: '16 X 16',
              value: 16,
            },
            {
              label: '25 X 25',
              value: 25,
            },
          ],
        },
        { type: 'number', label: 'Num. of puzzles', dataField: 'npuzzles' },
        {
          type: 'select',
          label: 'Difficulty',
          dataField: 'difficulty',
          options: [
            {
              label: 'Easy',
              value: 'Easy',
            },
            {
              label: 'Medium',
              value: 'Medium',
            },
            {
              label: 'Hard',
              value: 'Hard',
            },
          ],
        },
      ],
    },
  };

  const storiesFormInputs = {
    groups: {
      language: [
        {
          type: 'select',
          label: 'Language',
          dataField: 'language_id',
          options: languages.map(lang => ({
            label: lang.name,
            value: lang.id,
          })),
        },
      ],
    },
  };

  const crossWordsFormInputs = {
    groups: {
      generated_puzzles: [
        {
          type: 'select',
          label: 'Size',
          dataField: 'nrows',
          options: [
            {
              label: '13 x 13',
              value: 13,
            },
            {
              label: '15 X 15',
              value: 15,
            },
          ],
        },
        { type: 'number', label: 'Num. of puzzles', dataField: 'npuzzles' },
      ],
    },
  };

  const codeWordFormInputs = {
    groups: {
      generated_puzzles: [
        {
          type: 'select',
          label: 'Size',
          dataField: 'nrows',
          options: [
            {
              label: '13 x 13',
              value: 13,
            },
            {
              label: '15 X 15',
              value: 15,
            },
          ],
        },
        { type: 'number', label: 'Num. of puzzles', dataField: 'npuzzles' },
      ],
    },
  };

  return {
    generalFormInputs,
    triviaFormInputs,
    wordSearchFormInputs,
    numberSearchFormInputs,
    sudokuFormInputs,
    storiesFormInputs,
    crossWordsFormInputs,
    codeWordFormInputs,
  };
}
