import React, { useEffect } from 'react';
import { Text, Group, Rect, Line, Circle } from 'react-konva';
import LinkIndicator from '../LinkIndicator';
import {
  sliceIntoChunks,
  extractCellsContent,
  CROSSWORDS_DESIGN,
  CODEWORD_DESIGN,
} from 'settings/puzzels';

import { startCrossWordsEngine, startCodeWordEngine } from 'api/studio/manuscripts';

import _ from 'lodash';
const DUMMY = {
  grid_str:
    '*                * * *  *  ***              *  * * *  ** *           **      * * *     * *        **           **        * *     * * *      **           * **  * * *  *              ***  *  * * *                *       ',
  solution_str:
    'AABBEYS*CBAAXEWCAMACHOWA*C*A*AT*IC***INWAGNERIWAKEB*SD*D*T*PV**L*RSTYLEWHOAE**AWYLEM*M*I*TSWAS*A*RAAIBPHH**THINKINGCAP**BLACKTEA*N*CEOUA*N*B*STRUCK**TTAXPAYERSS*T**IA*A*B*DE*AERIALBOUTIQUE***AV*IB*X*E*ASKANCEPZACCEDER*BUCKEYE',
  small_numbers_str:
    '1,2, ,3, , , ,*,4,5,6,7,8, ,9,10, ,11, , , , ,12, ,*, ,*, ,*, , ,*, , ,*,*,*, , ,13, , , , , , ,14, , , ,15,*, , ,*, ,*, ,*, , ,*,*, ,*, ,16, , , , ,17, , , , ,*,*, ,18, , , , ,*, ,*, ,*, , ,19,20, ,*, ,*, , ,21, ,22,23, , ,*,*,24, , , , , , , , , , ,*,*,25, , , , , , , ,*, ,*,26, , ,27,28,*, ,*,29,*,30, , , , , ,*,*, ,31, , , , , , , , , ,*, ,*,*, ,32,*,33,*, ,*,34, ,*,35, , , , , ,36, , , , , , , ,*,*,*, ,37,*, , ,*, ,*, ,*,38, , , , ,39, , , ,40, , , , , , ,*,41, , , , , , ',
};

const TGridCrossWords = ({
  type,
  shapeProps,
  isEditMode,
  setIsEditMode,
  isSelected,
  setSelectedShapeId,
  onSelect,
  onDragStart,
  onDragMove,
  onDragEnd,
  onChange,
  trForceUpdate,
}) => {
  const shapeRef = React.useRef();
  const [dummy, setDummy] = React.useState(DUMMY);
  const [grid, setGrid] = React.useState([]);
  const nRows = React.useCallback(
    () =>
      shapeProps.staticLink.grid ? shapeProps.staticLink.grid.size[0] : shapeProps.staticLink.rows,
    [shapeProps.staticLink.grid, shapeProps.staticLink.rows]
  );

  const nCols = React.useCallback(
    () =>
      shapeProps.staticLink.grid ? shapeProps.staticLink.grid.size[1] : shapeProps.staticLink.cols,
    [shapeProps.staticLink.grid, shapeProps.staticLink.cols]
  );

  const puzzleShape = shapeProps.staticLink.puzzleShape?.toLowerCase() ?? null;

  React.useEffect(() => {
    const t =
      typeof shapeProps.text !== 'object'
        ? shapeProps.text
        : shapeProps.id.includes('puzzle')
        ? dummy.grid_str
        : dummy.solution_str;

    const cells = extractCellsContent(t);

    const rows = sliceIntoChunks(cells, nCols());
    const grid = rows.map((row, rowIndex) => {
      return row.map((cell, colIndex) => {
        return {
          text: cell,
          x: colIndex * (shapeProps.width / nCols()),
          y: rowIndex * (shapeProps.height / nRows()),
        };
      });
    });

    if (typeof shapeProps.text === 'object') {
      setDummy(shapeProps.text); // shapeProps.text => here is an object with solution_str, grid_str, small_numbers_str
    }

    setGrid(grid);
  }, [
    shapeProps.height,
    shapeProps.width,
    shapeProps.staticLink.rows,
    shapeProps.staticLink.cols,
    // shapeProps.fontSize,
    shapeProps.text,
    shapeProps.staticLink.grid,
    dummy.grid_str,
    dummy.solution_str,
    dummy.small_numbers_str,
  ]);

  const loadDummyPuzzle = _.debounce(() => {
    if (!isSelected) return;

    const design = CROSSWORDS_DESIGN[puzzleShape];

    if (!design) return;
    const { min } = design;
    const staticLink = { ...shapeProps.staticLink };

    // set rows and cols to min value if shape changed
    if (shapeProps.staticLink.rows < min) staticLink.rows = min;
    if (shapeProps.staticLink.cols < min) staticLink.cols = min;
    const fontSize = Math.max(
      Math.min(Math.round(shapeProps.height / nRows()) - 2, shapeProps.fontSize),
      6
    );

    const startPuzzleEngine = type === 'cross-words' ? startCrossWordsEngine : startCodeWordEngine;
    // generate puzzle from api and set dummy
    if (shapeProps.staticLink.rows && shapeProps.staticLink.cols)
      startPuzzleEngine({
        nrows: staticLink.rows,
        ncols: staticLink.cols,
        npuzzles: 1,
      }).then(res => {
        if (!res.success && (res.messages || res.errors)) {
          if (res.messages)
            res.messages.forEach(msg => {
              alert(msg);
              // setMsgs([{ type: 'warning', content: msg }]);
            });
          else if (res.errors)
            res.errors.forEach(err => {
              alert(err);
              // setMsgs([{ type: 'error', content: err }]);
            });
        } else if (res.cross_word_puzzles && res.cross_word_puzzles[0]) {
          setDummy(res.cross_word_puzzles[0]);

          onChange({
            ...shapeProps,
            staticLink,
            fontSize,
            text: {
              puzzle_shape: 'normal',
              grid_str: res.cross_word_puzzles[0].grid_str,
              solution_str: res.cross_word_puzzles[0].solution_str,
              small_numbers_str: res.cross_word_puzzles[0].small_numbers_str,
            },
          });
        } else if (res.code_word_puzzles && res.code_word_puzzles[0]) {
          setDummy(res.code_word_puzzles[0]);

          onChange({
            ...shapeProps,
            staticLink,
            fontSize,
            text: {
              puzzle_shape: 'normal', // to auto select shape in manuscript form
              grid_str: res.code_word_puzzles[0].grid_str,
              solution_str: res.code_word_puzzles[0].solution_str,
              small_numbers_str: res.code_word_puzzles[0].small_numbers_str,
            },
          });
        }

        trForceUpdate();
      });
  }, 1000);

  const load = () => {
    loadDummyPuzzle();
    return () => {
      loadDummyPuzzle.cancel();
    };
  };
  useEffect(load, []);

  useEffect(load, [
    shapeProps.staticLink.rows,
    shapeProps.staticLink.cols,
    shapeProps.staticLink.smallNumbers,
    shapeProps.staticLink.puzzleShape,
    shapeProps.staticLink.difficulty,
    shapeProps.staticLink.nWords,
  ]);

  const smallNumFontSize =
    shapeProps.staticLink.smallNumFontSize || shapeProps.height / nRows() / 4;

  return (
    <>
      {shapeProps.link && isSelected && !isEditMode && (
        <LinkIndicator shapeProps={shapeProps} offset={false} />
      )}
      <Group
        onMouseDown={onSelect}
        onTap={onSelect}
        onDblTap={() => {
          setSelectedShapeId(null); // UI fix - Don't remove it
          setIsEditMode(true);
          setSelectedShapeId(shapeProps.id);
        }}
        ref={shapeRef}
        {...shapeProps}
        draggable
        onDragStart={onDragStart}
        onDragMove={onDragMove}
        onDragEnd={e => onDragEnd(shapeProps, e)}
        onTransformEnd={e => {
          // transformer is changing scale of the node
          // and NOT its width or height- shapeRef.current.fontSize()/scale/100/2 - shapeRef.current.padding()/scale/100
          // but in the store we have only width and height
          // to match the data better we will re- shapeRef.current.fontSize()/scale/100/2 - shapeRef.current.padding()/scale/100set scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);

          onChange(
            {
              ...shapeProps,
              x: node.x(),
              y: node.y(), // set minimal value
              width: node.width() * scaleX,
              // height: node.width() * scaleX, // Quick fix
              height:
                shapeProps.staticLink &&
                shapeProps.staticLink.puzzleShape &&
                shapeProps.staticLink.puzzleShape === 'normal'
                  ? node.height() * scaleY
                  : node.width() * scaleX,
              scaleX: 1,
              scaleY: 1,
              rotation: node.rotation(),
            },
            true
          );
          trForceUpdate();
        }}
      >
        <Rect // this to constrain width
          key={'outline-' + shapeProps.staticLink.puzzleShape + shapeProps.id}
          x={0}
          y={0}
          width={shapeProps.width}
          height={shapeProps.height}
          stroke={shapeProps.staticLink.borders.color1}
          // stroke={'rgba(0,0,0,0)'}
          fill={'rgba(0,0,0,0)'}
          strokeWidth={
            shapeProps.staticLink.borders.checked1 ? shapeProps.staticLink.borders.width1 : 0
          }
        />
        {grid.map((cellsChunk, i) =>
          cellsChunk.map((word, j) => {
            const text = word.text;
            const smallNumbers = dummy.small_numbers_str;
            const smallText = smallNumbers.split(',');
            const smallNumber = smallText[i * nCols() + j] || ' ';

            return (
              <>
                {i < nRows() && text !== '*' && (
                  <Text
                    ellipsis
                    wrap={'none'}
                    key={'col' + j + '-' + 'row' + i}
                    {...shapeProps}
                    x={word.x + 0.3 * smallNumFontSize}
                    y={word.y + 0.3 * smallNumFontSize}
                    width={shapeProps.width / nCols()}
                    height={shapeProps.height / nRows()}
                    lineHeight={shapeProps.lineHeight}
                    text={text}
                    rotation={0}
                    verticalAlign={'middle'}
                  />
                )}
                {i < nRows() && text === '*' && (
                  <Rect
                    key={'col' + j + '-' + 'row' + i}
                    x={word.x}
                    y={word.y}
                    width={shapeProps.width / nCols()}
                    height={shapeProps.height / nRows()}
                    fill={'#000000'}
                  />
                )}
                {i < nRows() && smallNumber && (
                  <Text
                    key={'small-text--col' + j + '-' + 'row' + i}
                    text={smallNumber.trim()}
                    x={word.x + shapeProps.height / nRows() / 8}
                    y={word.y + shapeProps.height / nRows() / 8}
                    fill={shapeProps.fill}
                    fontSize={smallNumFontSize}
                  />
                )}
              </>
            );
          })
        )}

        {[...Array(nRows()).keys()].map(
          (row, i) =>
            i > 0 && (
              <Line
                points={[
                  0,
                  (shapeProps.height / nRows()) * i,
                  shapeProps.width,
                  (shapeProps.height / nRows()) * i,
                ]}
                stroke={shapeProps.staticLink.borders.color2}
                strokeWidth={
                  shapeProps.staticLink.borders.checked2 ? shapeProps.staticLink.borders.width2 : 0
                }
              />
            )
        )}
        {[...Array(nCols()).keys()].map(
          (col, j) =>
            j > 0 && (
              <Line
                points={[
                  (shapeProps.width / nCols()) * j,
                  0,
                  (shapeProps.width / nCols()) * j,
                  shapeProps.height,
                ]}
                stroke={shapeProps.staticLink.borders.color2}
                strokeWidth={
                  shapeProps.staticLink.borders.checked2 ? shapeProps.staticLink.borders.width2 : 0
                }
              />
            )
        )}
      </Group>
    </>
  );
};

export default TGridCrossWords;
