import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { login } from 'store/auth';
import axios from 'axios';
import { getOneAccount } from 'api/settings/accounts';
import { asyncLogout } from 'api/auth';

const isTokenExpired = expires_at => {
  const now = new Date();
  const expiry_date = new Date(expires_at);
  if (now > expiry_date) {
    return true;
  }
  return false;
};

function AuthObserver({ children }) {
  const [isInit, setIsInit] = useState(true);

  const { user, access_token, refresh_token, keep_login, expires_at } = useSelector(
    state => state.auth
  );

  const savedUser = JSON.parse(localStorage.getItem('user'));
  const savedAccessToken = JSON.parse(localStorage.getItem('access_token'));
  const savedRefreshToken = JSON.parse(localStorage.getItem('refresh_token'));
  const savedKeepLogin = JSON.parse(localStorage.getItem('keep_login'));
  const savedExpiresAt = JSON.parse(localStorage.getItem('expires_at'));

  const dispatch = useDispatch();
  const dispatchLoginFromLocalStorage = useCallback(() => {
    dispatch(
      login({
        user: savedUser,
        access_token: savedAccessToken,
        refresh_token: savedRefreshToken,
        keep_login: savedKeepLogin,
        expires_at: savedExpiresAt,
        from_local_storage: true,
      })
    );
  }, [dispatch, savedUser, savedAccessToken, savedRefreshToken, savedKeepLogin, savedExpiresAt]);

  const inLocalNotInStore = !!(
    savedUser &&
    savedAccessToken &&
    savedRefreshToken &&
    !user &&
    !access_token &&
    !refresh_token
  );

  useEffect(() => {
    // Auto login from local storage on initial load
    if (inLocalNotInStore && isInit) {
      dispatchLoginFromLocalStorage();
      setIsInit(false);
    }
  }, [inLocalNotInStore, isInit, dispatchLoginFromLocalStorage, setIsInit]);

  useEffect(() => {
    if (!user || !access_token) return;
    // Update user anyway for permissions and profile pic
    const { id } = user;
    if (id === undefined) return;
    getOneAccount(id).then(res => {
      dispatch(login({ user: res }));
    });
  }, [access_token]);

  useEffect(() => {
    if (refresh_token)
      axios.interceptors.response.use(
        response => response,
        error => {
          if (
            error.response &&
            (error.response.status === 403 ||
              error.response.status === 401 ||
              isTokenExpired(expires_at))
          )
            dispatch(asyncLogout());
        }
      );
  }, [refresh_token, dispatch]);

  return children;
}

export default AuthObserver;
