import React from 'react';
import IncludeSvg from 'components/common/IncludeSvg';

function ListControls({
  search,
  setSearch,
  setFocus,
  listName,
  sortDir,
  toggleSort,
  letterCase,
  toggleLetterCase,
  removeDuplicates,
  selectedItems,
  deleteSelected,
  dark = false,
}) {
  return (
    <div className={`list__top-controls shadow-sm border`}>
      <input
        className="list__top-controls--search"
        placeholder="Search..."
        autoComplete="off"
        type="text"
        onChange={e => {
          e.stopPropagation();
          setSearch(e.target.value);
        }}
        value={search}
        id={`${listName}-option-${-1}`}
        onClick={e => setFocus(-1)}
      />
      <div className={'list__top-controls--btns'}>
        <button title="Sort Words" onClick={toggleSort}>
          {sortDir}
        </button>
        <span
          title="Sort Words"
          onClick={toggleSort}
          className={`truncate ${sortDir === 'Descending' ? 'rotate-180' : 'rotate-0'}`}
        >
          <IncludeSvg name="arrow-drop-down" />
        </span>
        <span></span>
        <button title="Toggle Word Style" onClick={toggleLetterCase}>
          {letterCase}
        </button>
        <button title="Remove Duplicates" onClick={removeDuplicates}>
          <IncludeSvg name="no-duplicates"></IncludeSvg>
        </button>
        <button title="Delete Selected" disabled={!selectedItems.length} onClick={deleteSelected}>
          <IncludeSvg name="delete"></IncludeSvg>
        </button>
      </div>
    </div>
  );
}

export default ListControls;
