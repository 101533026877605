import { useEffect, useState } from 'react';
import useAutoLogin from 'hooks/use-auto-login';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import loginImage from 'assets/imgs/login.png';
import Spinner from 'components/common/Spinner';

import { getAuthToken } from 'api/auth';
import { useGlobalContext } from 'state/hooks/global';
import { clearError } from 'store/auth';

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [keepLogin, setKeepLogin] = useState(false);
  const { setShowSpinner } = useGlobalContext();
  const { error } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = data => {
    setIsLoading(true);
    dispatch(getAuthToken({ email: data.email, password: data.password, keep_login: keepLogin }));
  };

  const clearAuthErr = () => {
    dispatch(clearError());
  };

  useEffect(() => {
    if (error) {
      setIsLoading(false);
    }
  }, [error]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowSpinner(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  useAutoLogin(); // will redirect to '/' or '/from-path' if user is logged in

  return (
    <div className="form__login-wrapper min-vh-100">
      <form className="form__login sm-col-12 md-col-6 lg-col-10" onSubmit={handleSubmit(onSubmit)}>
        <div className="form__login-image-wrapper">
          <img alt="Funcrane" src={loginImage} />
          <p>Funcrane</p>
        </div>
        <div className="form-group">
          &nbsp;
          {error && (
            <div className="error text-center h5" role="alert">
              {error}
            </div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="email">User Name / E-mail</label>
          <input
            className="form-control"
            id="email"
            type="text"
            autoComplete="username"
            placeholder="Your email address"
            onInput={clearAuthErr}
            {...register('email', {
              required: 'Email is required!',
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Email is not correct!',
              },
            })}
          />
          {errors.email && (
            <span className="error" role="alert">
              &nbsp;{errors.email.message}
            </span>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            className="form-control"
            id="password"
            type="password"
            autoComplete="current-password"
            placeholder="Your password"
            onInput={clearAuthErr}
            {...register('password', {
              required: 'Password is required!',
              minLength: {
                value: 8,
                message: 'Password is not correct',
              },
            })}
          />
          {errors.password && (
            <span className="error" role="alert">
              &nbsp;{errors.password.message}
            </span>
          )}
        </div>
        <div className="form-group form-check">
          <input
            className="form-check-input "
            id="check"
            type="checkbox"
            onChange={() => setKeepLogin(!keepLogin)}
          />
          <label className="form-check-label" htmlFor="check">
            Keep me logged in.
          </label>
        </div>
        <div className="form-group">
          <button
            className="btn d-flex justify-content-center align-items-center"
            onClick={clearAuthErr}
          >
            Log in <span className="mx-2">{isLoading && <Spinner type={'local'} />}</span>
          </button>
        </div>
        <p>&copy; {new Date().getFullYear()} Funcrane | All Rights reserverd.</p>
      </form>
    </div>
  );
}

export default Login;
