import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from 'state/hooks/global';
import { useStudioContext } from 'hooks/studio';
import { useIsShiftDown } from 'hooks/util';
import { editTemplate, deleteTemplate } from 'api/studio/templates';
import TemplateForm from 'components/forms/TemplateForm';
import Pages from './Pages';

import Confirm from 'components/common/Confirm';
import StageTopControls from './StageTopControls';
import './TemplatesStage.scss';
import { useSelector } from 'react-redux';
import { collectLinksMapFromFormats, createSegmentsFromLinksMap } from 'helpers/studio';
import _, { update } from 'lodash';

const isEven = n => {
  return !(n % 2) && n !== 0;
};

function TemplatesStage({ template, show, toRemove, setToRemove, allowedTags }) {
  const { user } = useSelector(state => state.auth);
  const canChangeTemplates = user?.resources_permissions?.template?.change_template;

  const routerHistory = useHistory();
  const { isShiftDown } = useIsShiftDown();
  const { setMsgs, pageSizes } = useGlobalContext();
  const { templates, setTemplates } = useStudioContext();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPages, setSelectedPages] = useState([1]);
  const [pages, setPages] = useState([]);
  const [pageSelectorMode, setPageSelectorMode] = useState('single');
  const [pageSelectorHandSide, setPageSelectorHandSide] = useState(0);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(1);
  const [from1, setFrom1] = useState(1);
  const [to1, setTo1] = useState(1);
  const [segments, setSegments] = useState(template.segments || []);
  const [checkedFormats, setCheckedFormats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [percentage, setPercentage] = useState(0);
  const [lastSelected, setLastSelected] = useState(1);

  // console.log('template', template)
  // useEffect(() => {
  //   // UI fix
  //   if (!show) {
  //     setLoading(true);
  //   } else {
  //     setTimeout(() => setLoading(false), 500);
  //   }
  // }, [show]);

  useEffect(() => {
    if (template && template.pages && template.pages.length) {
      // console.log(template.pages.map(page => page))
      // setPages(Array(template.number_of_pages).fill(null));

      setPages(
        template.pages.map(page =>
          page ? { id: page.id, title: page.title, links_arr: page.links_arr } : null
        )
      );
      setSegments(template.segments || []);
      // console.log(pages)
    } else {
      setPages(Array(template.number_of_pages).fill(null));
    }
  }, [template]);

  const handlePageClick = pageNumber => {
    if (
      (isEven(pageNumber) && pageSelectorHandSide === 2) ||
      (!isEven(pageNumber) && pageSelectorHandSide === 1)
    ) {
      return;
    }

    if (isShiftDown && selectedPages.length > 0) {
      setPageSelectorMode('range');
      if (pageNumber < selectedPages[0]) {
        setTo(selectedPages[0]);
        setFrom(pageNumber);
      } else {
        setFrom(selectedPages[0]);
        setTo(pageNumber);
      }
    } else {
      if (pageSelectorMode !== 'single') setPageSelectorMode('single');
      setFrom(pageNumber);
    }
  };

  useEffect(() => {
    if (from > to && pageSelectorMode === 'range') return;
    setLastSelected(from);
  }, [from]);

  useEffect(() => {
    if (from > to && pageSelectorMode === 'range') return;
    setLastSelected(to);
  }, [to]);
  useEffect(() => {
    if (from > to && pageSelectorMode === 'range') return;

    if (
      // from > to ||
      pageSelectorMode === 'single'
    ) {
      setTo(from);
    }

    if (from1 < to && !pageSelectorMode.includes('(2-ranges)')) setFrom1(to);
    if (to1 < from1 && !pageSelectorMode.includes('(2-ranges)')) setTo1(from1);

    let start = from < 0 ? 1 : from;
    let end = to > pages.length ? pages.length : to;

    let start1 = from1 < end ? +end : +from1;
    let end1 = to1 > pages.length ? +pages.length : +to1;

    // // Left
    // if (pageSelectorHandSide === 1 && !isEven(start)) {
    //   pageSelectorHandSide === 2 && isEven(start);
    //   if (start + 1 < pages.length) setFrom(start + 1);
    //   else setFrom(start - 1);
    // }
    // // Right
    // else if (pageSelectorHandSide === 2 && isEven(start)) {
    //   if (start - 1 > 0) setFrom(start - 1);
    //   else setFrom(start + 1);
    // }

    const length = end - start + 1;
    const length1 = end1 - start1 + 1;

    const filterFun = page => {
      if (pageSelectorHandSide === 1) return !(page % 2);
      else if (pageSelectorHandSide === 2) return !!(page % 2);
      else return true;
    };

    if (pageSelectorMode.includes('2-ranges')) {
      setSelectedPages([
        ...Array.from({ length }, (v, k) => k + start).filter(filterFun),
        ...Array.from({ length: length1 }, (v, k) => k + start1).filter(filterFun),
      ]);
    } else if (pageSelectorMode === 'range') {
      setSelectedPages(Array.from({ length }, (v, k) => k + start).filter(filterFun));
    } else if (pageSelectorMode === 'single') setSelectedPages([start]);
  }, [pages.length, pageSelectorMode, pageSelectorHandSide, from, to, from1, to1]);

  // useEffect(() => {
  //   if (from > to && pageSelectorMode === 'range') return;

  //   setCheckedFormats([]);
  //   if (from > to || pageSelectorMode === 'single') {
  //     setTo(from);
  //   }

  //   let start = from < 0 ? 1 : from;
  //   let end = to > pages.length ? pages.length : to;

  //   // Left
  //   if (pageSelectorHandSide === 1 && !isEven(start)) {
  //     pageSelectorHandSide === 2 && isEven(start);
  //     if (start + 1 < pages.length) setFrom(start + 1);
  //     else setFrom(start - 1);
  //   }

  //   // Right
  //   else if (pageSelectorHandSide === 2 && isEven(start)) {
  //     if (start - 1 > 0) setFrom(start - 1);
  //     else setFrom(start + 1);
  //   }

  //   const length = end - start + 1;
  //   const filterFun = page => {
  //     if (pageSelectorHandSide === 1) return !(page % 2);
  //     else if (pageSelectorHandSide === 2) return !!(page % 2);
  //     else return true;
  //   };

  //   if (pageSelectorMode === 'range')
  //     setSelectedPages(Array.from({ length }, (v, k) => k + start).filter(filterFun));
  //   else if (pageSelectorMode === 'single') setSelectedPages([start]);
  // }, [pages.length, pageSelectorMode, pageSelectorHandSide, from, to]);

  useEffect(() => {
    if (selectedPages.length && checkedFormats.length) {
      const list = [...pages];

      // Assign checked format to all selected pages
      const format = checkedFormats[0];
      selectedPages.forEach(pageIndex => {
        list[pageIndex - 1] = format;
      });

      // Assign checked formats to selected pages in corresponding order
      // checkedFormats.forEach((format, index) => {
      //   const pageIndex = selectedPages[index] - 1;
      //   list[pageIndex] = format;
      // });
      setPages(list);
    }
  }, [checkedFormats]);

  useEffect(() => {
    setCheckedFormats([]);
  }, [selectedPages]);

  useEffect(() => {
    // use lodash to check if segments changed or not
    if (_.isEqual(segments, template.segments)) return;
    handleSave({ updateSegmentsOnly: true });
  }, [segments, template.segments]);

  const collectContentTypes = links_map =>
    links_map.reduce((acc, curr) => {
      if (curr.links_arr && curr.links_arr.length) {
        curr.links_arr.forEach(link => {
          if (link.role && !acc.includes(link.role)) {
            acc.push(link.role);
          }
        });
      }
      return acc;
    }, []);

  const handleSave = ({ updateSegmentsOnly = false }) => {
    const links_map = collectLinksMapFromFormats({ pages });
    const content_types = collectContentTypes(links_map);

    let _segments = [];
    if (segments.length) _segments = segments;
    else _segments = createSegmentsFromLinksMap(links_map);

    let data;
    if (updateSegmentsOnly) data = { is_published: true, segments: _segments };
    else
      data = {
        is_published: true,
        pages: pages.map(page => (page ? page.id : null)),
        number_of_pages: pages.length,
        links_map,
        content_types,
        segments: _segments,
      };

    editTemplate(data, template.id).then(res => {
      if (res.success) {
        const list = [...templates];
        const index = list.findIndex(el => el.id === res.template.id);

        // ------
        list[index].pages = [...pages];
        list[index] = { ...list[index], ...res.template };
        // @TODO: this should equal to res.template only but for now we are updating only the segments
        // list[index] = {...res.template}; @TODO: uncomment this line when backend is ready

        setTimeout(() => {
          setTemplates(list);
          setSegments(res.template.segments);
          setMsgs([
            {
              type: 'success',
              content: updateSegmentsOnly
                ? 'Segments updated successfully!'
                : 'Template updated successfully!',
            },
          ]);
        }, 100);
      }
    });
  };

  const removeTemplateFromStage = () => {
    const list = [...templates];
    const index = list.findIndex(item => item.id === template.id);
    if (index > -1) list.splice(index, 1);
    setTemplates(list);
    if (!list.length) routerHistory.push('/home/templates');
  };

  const handleCancel = () => {
    setIsOpen(true);
  };

  const handleCancelAfterConfirm = c => {
    if (c) {
      handleSave();
      removeTemplateFromStage(template);
    } else {
      if (!template.is_published) {
        deleteTemplate(template.id).then(res => {
          if (res.success) {
            removeTemplateFromStage(template);
          }
        });
      } else {
        removeTemplateFromStage(template);
      }
    }
  };

  const addPageAfterSelected = () => {
    setPageSelectorHandSide(0);

    const list = [...pages];
    const index = selectedPages[0] || 1;
    list.splice(index, 0, null);
    setPages(list);
    setFrom(index > 0 ? index + 1 : index);
  };

  const deleteSelectedPage = () => {
    setPageSelectorHandSide(0);
    const isDeleteAll = selectedPages[0] - 1 === 0 && selectedPages.length === pages.length;
    const list = [...pages];
    const index = isDeleteAll ? selectedPages[0] : selectedPages[0] - 1;
    list.splice(index, to - from + 1);
    setTo(isDeleteAll ? 1 : selectedPages[0] - 1);
    setSelectedPages(isDeleteAll ? [1] : [selectedPages[0] - 2]);
    setPages(list);
    setFrom(index === 0 ? index + 1 : index);
  };

  const handleStageControls = action => {
    switch (action) {
      case 'insert':
        addPageAfterSelected();
        break;
      case 'delete':
        deleteSelectedPage();
        break;
      default:
        alert('not supported as stage actoin');
    }
  };

  useEffect(() => {
    if (toRemove === template.id) {
      handleCancel();
      setToRemove(null);
    }
  }, [toRemove]);

  // const pageSize = (() => {
  //   const p = pageSizes.find(size => size.id === template.page_size);
  //   return p ? `${p.width}" x ${p.height}" | ` : '';
  // })();
  const pageSize = (() => {
    const p = pageSizes.find(size => size.id === template.page_size);
    const bleeding =
      template.pages && template.pages.length && template.pages[0] && template.pages[0].bleeding;
    const haveBleeding =
      bleeding && bleeding.top + bleeding.right + bleeding.bottom + bleeding.left;
    return p
      ? `${p.width.toFixed(2)}"x${p.height.toFixed(2)}"` +
          ' ' +
          (haveBleeding
            ? `(${(p.width + bleeding.right + bleeding.left).toFixed(2)}"x${(
                p.height +
                bleeding.top +
                bleeding.bottom
              ).toFixed(2)}")`
            : '')
      : '';
  })();
  return (
    <div className={`${show ? 'd-flex' : 'd-none'} align-items-stretch`}>
      <div className="format-stage-area w-100">
        <StageTopControls
          title={
            pageSelectorMode === 'single'
              ? `Page ${from} of ${pages.length}`
              : `Pages (${from} to ${to}) of ${pages.length}`
          }
          subTitle={
            pageSelectorMode === 'single'
              ? `Assigned format: ${
                  pages[selectedPages[0] - 1] ? pages[selectedPages[0] - 1]['title'] : 'none'
                }`
              : null
          }
          subTitleLink={
            pageSelectorMode === 'single' &&
            pages[selectedPages[0] - 1] &&
            pages[selectedPages[0] - 1]['id']
              ? window.location.origin +
                '/home/formats/workspace?ids=' +
                pages[selectedPages[0] - 1]['id']
              : '#'
          }
          cb={handleStageControls}
          pageSize={pageSize}
          actions={
            canChangeTemplates
              ? [
                  // { name: 'focus', disabled: selectedPages.length !== 1 },
                  { name: 'insert', disabled: selectedPages.length !== 1 },
                  {
                    name: 'delete',
                    disabled: selectedPages.length < 1 || pages.length === 1,
                  },
                ]
              : []
          }
        />
        <Pages
          loading={loading}
          setLoading={setLoading}
          percentage={percentage}
          setPercentage={setPercentage}
          pages={pages}
          pageSizes={pageSizes}
          template={template}
          handlePageClick={handlePageClick}
          selectedPages={selectedPages}
          show={show}
          lastSelected={lastSelected}
        />
      </div>

      {canChangeTemplates && (
        <div className="form form__studio noselect">
          <TemplateForm
            pageSelectorMode={pageSelectorMode}
            setPageSelectorMode={setPageSelectorMode}
            pageSelectorHandSide={pageSelectorHandSide}
            setPageSelectorHandSide={setPageSelectorHandSide}
            from={from}
            setFrom={setFrom}
            to={to}
            setTo={setTo}
            from1={from1}
            setFrom1={setFrom1}
            to1={to1}
            setTo1={setTo1}
            segments={segments}
            setSegments={setSegments}
            allowedTags={allowedTags}
            checkedFormats={checkedFormats}
            setCheckedFormats={setCheckedFormats}
            pages={pages}
            template={template}
          />
          <div className="d-flex justify-content-center lower-buttons">
            <div className="d-flex w-75">
              <button className="btn-white-forms mx-2" onClick={handleCancel}>
                Cancel
              </button>
              <button className="btn-blue-forms mx-2" onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      <Confirm
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={'Save Changes!'}
        msg={'Do you want to save changes?'}
        confirmText={'Save'}
        cancelText={"Don't Save"}
        handleConfirm={() => handleCancelAfterConfirm(true)}
        handleCancel={() => handleCancelAfterConfirm(false)}
      />
    </div>
  );
}

export default TemplatesStage;
