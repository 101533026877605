import React from 'react';
import TGridSudoku from './grids/TGridSudoku';
import TGridWordSearch from './grids/TGridWordSearch';
import TGridCrossWords from './grids/TGridCrossWords';
import TGridStatic from './grids/TGridStatic';

const TGrid = React.memo(props => {
  return (
    <>
      {props.type === 'sudoku' && <TGridSudoku {...props} />}
      {props.type === 'word-search' && <TGridWordSearch {...props} />}
      {props.type === 'number-search' && <TGridWordSearch {...props} />}
      {(props.type === 'cross-words' || props.type === 'code-word') && (
        <TGridCrossWords {...props} />
      )}
      {(props.type === 'alphabets-hz' ||
        props.type === 'alphabets-vt' ||
        props.type === 'map-hz' ||
        props.type === 'map-vt') && <TGridStatic {...props} />}
    </>
  );
});

export default TGrid;
